<template>
    <div id="content" class="register" :class="[{ detail: addeditId, portrait: ['portrait'].includes($mq) }]">
        <!-- PORTRAIT -->
        <template v-if="['portrait'].includes($mq) && !addeditId">
            <div class="header records-header" v-if="!detailId">
                <!-- ON HIGHLIGHT -->
                <template v-if="!selectedCat">
                    <div class="icon list" v-if="Object.keys(categories).length > 1" :class="{ selected: !searchBar }" @click="displayCategories()"></div>
                    <div class="icon search" v-if="Object.values(templates).length > 0" @click="searchSwitch()" :class="{ selected: searchBar }"></div>
                </template>
                <!-- ON A CATEGORY -->
                <div v-else-if="selectedCat" class="top-navbar" @click="changeCategory()">
                    <!-- <div class="ball" :class="{ highlight: selectedCat.highlight }" :style="{ backgroundColor: categoryColor }"></div> -->
                    <!-- <div class="name" :class="{ all: selectedCat == categories['all'] }">{{ selectedCat.name }}</div> -->

                    <SimpleNavigationBar @back-button-click="changeCategory" :color="categoryColor" :title="selectedCat.name" />

                    <div class="icon search" v-if="Object.values(templates).length > 0 && (!selectedCat || selectedCat == categories['all'])" @click.stop="searchSwitch()" :class="{ selected: searchBar }"></div>
                </div>
            </div>

            <SearchBar v-if="(searchBar || search) && !detail" :search="search" @on-search="searchBarInput" @on-button-click="cleanSearch" />

            <template v-if="(!detailId && Object.keys(categories).length <= 1) || (selectedCat && !detail && !detailId) || (this.searchBar && !detail && !detailId)">
                <div class="items hide-scrollbar" :class="{ searching: searchBar }">
                    <item v-for="reg in filteredTemplates" :key="reg.id" :catSelected="selectedCat" :item="reg" @sendedId="openDetail" @sendedAddedit="createRegister" :checkPermission="checkPermissions(reg.id)"></item>
                    <EmptyPage v-if="Object.values(templates).length == 0" :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.record_template_description')"></EmptyPage>
                </div>
            </template>
            <template v-if="detail">
                <div class="content hide-scrollbar resume">
                    <resume @goBack="back" :hasError="errorRegister()" v-if="detailId && register && !addeditId" :register="register" @sendedAddedit="openAddedit" :detailId="detailId"></resume>
                </div>
            </template>
        </template>

        <!-- LANDSCAPE -->
        <template v-else-if="['landscape', 'desktop'].includes($mq) && !addeditId">
            <div class="content hide-scrollbar" :class="{ fullWidth: Object.keys(categories).length <= 1 || detail }">
                <SearchBar v-if="!detailId && Object.values(templates).length > 0" :search="search" @on-search="searchBarInput" @on-button-click="cleanSearch" />

                <template v-if="!detailId">
                    <div class="logs-items-container">
                        <item v-for="reg in filteredTemplates" :catSelected="selectedCat" :item="reg" :key="reg.id" @sendedId="openDetail" @sendedAddedit="createRegister" :checkPermission="checkPermissions(reg.id)"></item>
                    </div>
                </template>

                <EmptyPage v-if="!detailId && Object.values(templates).length == 0" :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.record_template_description')"></EmptyPage>

                <resume @goBack="back" :hasError="errorRegister()" v-if="detailId && register && !addeditId" :register="register" :detailId="detailId" @sendedAddedit="openAddedit"></resume>
                <p v-if="detailId && register && !addeditId && !register" class="empty">
                    {{ $t('register.empty_templates') }}
                </p>
            </div>
        </template>

        <detail v-if="detailId && addeditId" :cType="checkType" :gId="addeditId" :showErrors="showErrors" :permissions="detailtPermissions()" @saveButton="handleSaveAction"></detail>

        <column v-if="!loading && !((['portrait'].includes($mq) && selectedCat) || (['portrait'].includes($mq) && searchBar)) && Object.keys(categories).length > 1 && !detail" :showHighLight="showHighlightCat" :templates="templates" :gSelected="selectedCat" @changeCategory="changeCategory"></column>
    </div>
</template>

<script>
import Column from '@/components/domain/register/column.vue'
import { FORMAT } from '@/constants'
import item from '@/components/domain/register/item'
// Este detail es la tabla
import resume from '@/components/domain/register/resume'
// Este detail es el comun de cheklist para crear
import detail from '@/components/domain/register/detail'
import EmptyPage from '@/components/layout/EmptyPage'
import SearchBar from '../components/domain/ui/SearchBar.vue'
import SimpleNavigationBar from '@/components/domain/ui/SimpleNavigationBar.vue'

export default {
    name: 'Register',
    props: { checkType: Number },
    components: {
        item,
        resume,
        detail,
        EmptyPage,
        Column,
        SearchBar,
        SimpleNavigationBar
    },
    data() {
        return {
            detail: false,
            addedit: false,
            FORMAT: FORMAT,
            searchBar: false,
            search: '',
            timerSearch: false,
            selectedCat: false,
            loading: true,
            showHighlightCat: true,
            showErrors: false,
            SimpleNavigationBar
        }
    },
    computed: {
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },

        templates() {
            var templates = { ...this.$store.getters['register/getTemplates'] }
            var result = {}
            let userRol = this.$store.getters['employee/getRol'](this.user.rol)
            for (var templates_index in templates) {
                var template = templates[templates_index]
                if (template && template.config != false && template.config.nRoles && template.config.nRoles.length > 0) {
                    const templateRoles = template.config.nRoles
                    let bRegisterAdded = false
                    templateRoles.forEach((role) => {
                        if (role.roleId == this.user.rol) {
                            bRegisterAdded = true
                            result[templates_index] = template
                        }
                    })

                    if (userRol.level >= 8 && !bRegisterAdded) {
                        result[templates_index] = template
                    }
                } else if (template && template.config != false && template.config.roles && Object.keys(template.config.roles).length > 0) {
                    var templateroles = template.config.roles

                    if ((templateroles && templateroles.includes(this.user.rol)) || userRol.level >= 8) {
                        result[templates_index] = template
                    }
                    // SI NO TIENE NINGUN ROL SE MUESTRA
                } else result[templates_index] = template
            }
            return result
        },
        filteredTemplates() {
            if (!this.loading) {
                if (this.templates) {
                    // filter templates by search
                    if (this.search != '') {
                        const templatesSearched = this.$store.getters['register/getTemplatesSearched'](this.search)
                        let result = {}
                        let userRol = this.$store.getters['employee/getRol'](this.user.rol)
                        for (var templates_index in templatesSearched) {
                            var template = templatesSearched[templates_index]
                            if (template && template.config != false && template.config.nRoles && template.config.nRoles.length > 0) {
                                const templateRoles = template.config.nRoles
                                templateRoles.forEach((role) => {
                                    if (role.roleId == this.user.rol) {
                                        result[templates_index] = template
                                    }
                                })

                                if (userRol.level >= 8) {
                                    result[templates_index] = template
                                }
                            } else if (template && template.config != false && template.config.roles && Object.keys(template.config.roles).length > 0) {
                                var templateroles = template.config.roles
                                if ((templateroles && templateroles.includes(this.user.rol)) || userRol.level >= 8) {
                                    result[templates_index] = template
                                }
                                // SI NO TIENE NINGUN ROL SE MUESTRA
                            } else result[templates_index] = template
                        }

                        return result
                    }
                    // filter templates by selectedCat
                    if (this.selectedCat && this.selectedCat.id != 'all') {
                        var result = {}
                        for (var templates_index in this.templates) {
                            var template = this.templates[templates_index]
                            if (template.category_id == this.selectedCat.id) {
                                result[templates_index] = template
                            }
                        }
                        return result
                    } else {
                        return this.templates
                    }
                }
            }
        },
        register() {
            return this.$store.getters['register/getItems']
        },

        categoryColor() {
            return this.selectedCat.parent_id ? (this.categories[this.selectedCat.parent_id] ? this.categories[this.selectedCat.parent_id].color : '#273151') : this.categories[this.selectedCat.id] ? this.categories[this.selectedCat.id].color : '#273151'
        },
        detailId: {
            get() {
                return this.detail
            },
            set(value) {
                this.detail = value
            }
        },
        addeditId: {
            get() {
                return this.addedit
            },
            set(value) {
                this.addedit = value
            }
        },
        categories() {
            var self = this
            var categories = this.$store.getters['register/getCategories']()

            for (var category_index in categories) {
                if (categories[category_index].id != 'all') {
                    var result = Object.keys(this.templates).filter(function (tpl) {
                        return self.templates[tpl].category_id === categories[category_index].id
                    })
                    if (Object.values(result).length == 0) {
                        delete categories[category_index]
                    }
                }
            }

            return categories
        },
        userAppAccess() {
            return this.$store.getters['loginUser/getHasAccessApp']
        },
        itemsExcluded() {
            return this.$store.getters['tasks/getItemsExcluded']
        },
        roles() {
            return this.$store.getters['employee/getAllRoles']
        },
        registerWithTempId() {
            return this.$store.getters['register/getItemsWithTemporalId']
        }
    },
    methods: {
        rolesWithWritePermissions() {
            const permissions = this.templates[this.detailId]
            const roles = []
            if (permissions && permissions.config && permissions.config.nRoles && permissions.config.nRoles.length > 0) {
                const idOfRoles = permissions.config.nRoles.map((role) => {
                    if (role.permissions.includes('w')) {
                        return role.roleId
                    }
                })
                Object.values(this.roles).map((role) => {
                    if (idOfRoles.includes(role.id)) {
                        roles.push(role.name)
                    }
                })
            }

            return roles
                .join(', ')
                .toString()
                .replace(/,(?=[^,]*$)/, ' ' + this.$t('answer.or'))
        },
        detailtPermissions() {
            if (this.detailId) {
                return this.checkPermissions(this.detailId)
            }
        },
        back() {
            this.detail = false
            this.$bar.reset()
            this.$router.push({ name: 'Register' })
        },
        searchBarInput(e) {
            const self = this
            if (this.timerSearch) {
                clearTimeout(this.timerSearch)
                this.timerSearch = null
            }
            this.timerSearch = setTimeout(() => {
                self.search = e.target.value
                if (self.$mq != 'portrait') {
                    self.selectedCat = self.categories['all']
                }
            }, 800)
        },
        cleanSearch() {
            this.search = ''
        },
        searchSwitch() {
            if (!this.searchBar) {
                this.searchBar = true
                this.search = ''
                this.selectedCat = this.categories['all']
            } else {
                this.searchBar = false
                this.search = ''
            }
        },
        displayCategories() {
            this.search = ''
            this.searchBar = false
        },
        changeCategory(category) {
            if (!category) {
                this.searchBar = false
            }
            this.selectedCat = category
            this.search = ''
        },
        load(refresh = true) {
            var self = this
            var categories = this.categories

            self.detailId = self.$route.params.id
            self.addeditId = self.$route.params.edit
            log('$$ permissions', this.checkPermissions(this.detailId))

            if (self.detailId && !self.addeditId) {
                self.showErrors = false
                self.$store.dispatch('register/loadItems', { template: self.detailId, noRefresh: self.$route.params.noRefresh }).then(function () {
                    if (Object.keys(categories).length <= 1) {
                        self.$store.dispatch('register/loadCategories').then(function () {
                            self.loading = false
                            self.openDetail(self.detailId)
                        })
                    } else {
                        self.loading = false
                        self.openDetail(self.detailId)
                    }
                })
            } else if (self.detailId && self.addeditId) {
                self.$store.dispatch('register/loadItems', { template: self.detailId, noRefresh: self.$route.params.noRefresh }).then(function () {
                    if (Object.keys(categories).length <= 1) {
                        self.$store
                            .dispatch('register/loadCategories')
                            .then(function () {
                                self.openAddedit(self.addeditId)
                            })
                            .catch(function (error) {
                                logError(error)
                                self.$overlay.hide()
                                self.$popup.alert({
                                    closable: false,
                                    blocking: false,
                                    message: self.$t('popup.register.offline_add'),
                                    textSave: self.$t('popup.audits.exit'),
                                    callSave: function (response) {
                                        self.$popup.close()
                                    }
                                })
                            })
                    } else {
                        self.openAddedit(self.addeditId)
                    }
                })
            } else {
                if (Object.keys(categories).length <= 1) {
                    self.$store.dispatch('register/loadCategories').then(function () {
                        self.loading = false
                        if (!['portrait'].includes(self.$mq)) {
                            if (!self.selectedCat) {
                                self.selectedCat = Object.values(self.categories).length > 1 ? self.categories['all'] : false
                            }
                        }
                    })
                } else {
                    self.loading = false
                    if (!['portrait'].includes(self.$mq)) {
                        if (!self.selectedCat) {
                            self.selectedCat = Object.values(self.categories).length > 1 ? self.categories['all'] : false
                        }
                    }
                }
            }
        },
        errorRegister() {
            if (this.templates.length == 0 || this.detailId == undefined) {
                return false
            }
            for (var template in this.templates) {
                var idx = this.templates[template]
                log(idx, this.detailId)
                if (idx.id == this.detailId) {
                    return false
                }
            }
            return true
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        openDetail(id) {
            var self = this

            if (self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                self.goToDetail(id)
            } else {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function (response) {
                        if (response) {
                            self.goToDetail(id)
                            self.$popup.close()
                        }
                    }
                })
            }
        },
        goToDetail(id) {
            // console.log(" >> openDetail");
            var self = this

            if (!self.detailId) {
                // console.log("Hace la redireccion");
                self.$router.push({
                    name: 'RegisterDetail',
                    params: { id: id }
                })
            } else {
                // console.log("Continua con sus cosas de open detail");
                self.$bar.reset()

                // self.$bar.addAction('back', {
                //     customClass: 'back-portrait',
                //     callback: function() {
                //         self.back()
                //     },
                //     label: self.$t('menu_aux.action.back')
                // })

                // if (Object.values(this.register).length > 0 && this.userAppAccess) {
                //     self.$bar.addAction('create', {
                //         customClass: 'create-portrait',
                //         callback: function () {
                //             self.openAddedit()
                //         },
                //         label: self.$t('menu_aux.action.create')
                //     })
                // }
            }
        },

        createRegister(id) {
            var self = this
            // console.log(" >> createRegister");

            if (!this.checkPermissions(id)) {
                this.$overlay.hide()
                this.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.audits.not_level_permission'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function (response) {}
                })
                return
            }

            //CASE OFFLINE FLAGS IS FALSE
            const canWorkOffline = this.$store.getters['getOffline']
            if (!fnCheckConnection() && !canWorkOffline) {
                this.$overlay.hide()
                this.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.register.offline_add'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
                return
            }

            if (self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                self.$router.push({
                    name: 'RegisterEdit',
                    params: { id: id, edit: 'add' }
                })
            } else {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function (response) {
                        if (response) {
                            self.$router.push({
                                name: 'RegisterEdit',
                                params: { id: id, edit: 'add' }
                            })
                            self.$popup.close()
                        }
                    }
                })
            }
        },
        checkPermissions(id) {
            log('$$ id checkPermissions', id)
            const template = this.templates[id]
            const userRole = this.$store.getters['employee/getRol'](this.user.rol)
            if (userRole.level >= 8) {
                return true
            }

            if (template && template.config && !(template.config.nRoles || template.config.roles)) {
                return true
            }

            if (template && template.config && template.config.nRoles && template.config.nRoles.length > 0) {
                const templateRoles = template.config.nRoles
                let isValid = false

                templateRoles.forEach((role) => {
                    if (role.roleId == userRole.id && role.permissions.includes('w')) {
                        isValid = true
                        return
                    }
                })

                return isValid
            }

            return false
        },
        handleSaveAction() {
            const self = this

            if (!self.checkPermissions(self.detailId)) {
                self.$overlay.hide()
                self.$popup.alert({
                    message: self.$t('answer.timing.not_has_role_permission', {
                        name: self.user.name,
                        roles: self.rolesWithWritePermissions()
                    })
                })
                return
            }

            const validation = []
            const oData = {
                cType: self.checkType,
                check: self.addeditId,
                emp: self.$store.getters['loginUser/getLocalEmployee'],
                date: moment().unix(),
                values: []
            }
            const register = self.$store.getters['register/getById'](self.addeditId)

            if (register && Object.values(register.items).length > 0) {
                let incompleteItemFound = false
                Object.values(register.items).forEach((item) => {
                    if (item.mandatory && item.is_visible && ((!item.value && item.value !== 0) || item.value == null) && !self.itemsExcluded.includes(item.type)) {
                        log('$$ CHECK ITEMS', item)
                        validation.push(false)
                        self.showErrors = true

                        if (!item.complete_date && !incompleteItemFound) {
                            const element = document.getElementById(item.item_id)
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth', block: 'start' })
                            }
                            incompleteItemFound = true
                        }

                        return self.$snackbar.error({
                            message: self.$t('audit.save_error_uncomplete')
                        })
                    }
                })
            }

            if (validation.indexOf(false) === -1) {
                oData.values = JSON.stringify(oData.values)
                self.$overlay.loading()

                self.$store.dispatch('register/complete', oData).then((response) => {
                    if (response.status) {
                        self.$store.dispatch('register/loadTemplates', {}).then(() => {
                            self.$overlay.hide()
                            self.$router.push({
                                name: 'RegisterDetail',
                                params: { id: self.detailId }
                            })
                        })
                    } else {
                        self.$overlay.hide()
                        self.$popup.alert({
                            closable: false,
                            blocking: false,
                            message: self.$t('popup.register.offline_save'),
                            textSave: self.$t('popup.audits.exit'),
                            callSave: () => self.$popup.close()
                        })
                    }
                })
            }
        },
        openAddedit(id) {
            // console.log(" >> openAddedit");
            log('$$ openAddedit', id)
            var self = this
            self.$bar.reset()

            if ((id == self.addeditId && typeof id !== 'undefined' && id != 'add') || (typeof self.addeditId !== 'undefined' && self.addeditId != 'add')) {
                // console.log("Continua con sus cosas de ADDEDIT");
                self.$bar.reset()

                if (!self.register[id].complete_date && this.userAppAccess) {
                    self.$bar.addAction('save', {
                        callback: () => self.handleSaveAction()
                    })
                }

                // self.$bar.addAction('back', {
                //     customClass: 'back-portrait',
                //     callback: function() {
                //         self.$router.push({
                //             name: 'RegisterDetail',
                //             params: { id: self.detailId, noRefresh: true }
                //         })
                //     },
                //     label: self.$t('menu_aux.action.back')
                // })
            } else {
                if (id && typeof id !== 'undefined' && id != 'add') {
                    self.addeditId = id
                    // console.log("ID RESULTANTE: "+self.addeditId);
                    // console.log("Hace la redireccion");
                    self.$router.push({
                        name: 'RegisterEdit',
                        params: { id: self.detailId, edit: self.addeditId }
                    })
                } else {
                    if (this.detailId && !this.checkPermissions(this.detailId)) {
                        this.$overlay.hide()
                        this.$popup.alert({
                            closable: false,
                            blocking: false,
                            message: self.$t('popup.audits.not_level_permission'),
                            textSave: self.$t('popup.audits.exit'),
                            callSave: function (response) {}
                        })
                        return
                    }
                    self.$overlay.loading()
                    self.$store
                        .dispatch('register/add', {
                            tpl_id: self.detailId,
                            cType: self.checkType
                        })
                        .then(function (response) {
                            self.$overlay.hide()
                            self.addeditId = response.id
                            self.$router.push({
                                name: 'RegisterEdit',
                                params: {
                                    id: self.detailId,
                                    edit: self.addeditId
                                }
                            })
                        })
                        .catch(function (error) {
                            logError(error)
                            self.$overlay.hide()
                            self.$popup.alert({
                                closable: false,
                                blocking: false,
                                message: self.$t('popup.register.offline_add'),
                                textSave: self.$t('popup.audits.exit'),
                                callSave: function (response) {
                                    self.$popup.close()
                                }
                            })
                        })
                }
            }
        }
    },
    created() {
        // console.log("created");
        this.$bar.reset()
        this.load()
    },
    watch: {
        $route: 'load',
        $mq: function (newChange, oldChange) {
            if (newChange == 'landscape') {
                if (!this.selectedCat && !this.search) {
                    this.selectedCat = Object.values(this.categories).length > 1 ? this.categories['all'] : false
                }
            } else if (newChange == 'portrait') {
                if (this.search && this.search != '') {
                    this.searchBar = true
                    this.selectedCat = false
                }
            }
        },
        //Ver la ruta si tiene un temporal id, para cambiarlo por un id de temporal_id
        register(newVal, oldVal) {
            if (this.registerWithTempId.length > 0 && this.$route.params.edit) {
                const routerEdit = this.$route.params.edit
                this.registerWithTempId.forEach((register) => {
                    if (register.temporal_id == routerEdit) {
                        log('$$ register', register.id, register.temporal_id, routerEdit, this.addeditId)
                        this.$router.push({
                            name: 'RegisterEdit',
                            params: { id: this.detailId, edit: register.id, noRefresh: true }
                        })
                    }
                })
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$snackbar.hide()
        next()
    },
    beforeDestroy() {
        this.$bar.hide()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// LANDSCAPE STYLES
#content.register {
    padding-left: 0px;
    $column-width: 30%;
    $column-max-width: 0px;
    $content-width: 100%;

    > .content {
        $content-margin: 12px;
        float: right;
        padding: 8px !important;

        // New style
        @include border-radius($default-border-radius);
        background-color: $default-bg-color;
        width: calc(70%) !important;
        height: calc(100% - #{$content-margin}) !important;
        border: none;

        @include display-flex();
        @include flex-direction(row);
        @include flex-wrap(wrap);
        @include align-content(flex-start);

        .logs-items-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 12px;
        }

        &.empty {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-end);
            padding-top: 75px;

            .message {
                @include display-inline-flex();
                @include align-items();
                @include background($image: img('alexpose_04.svg'), $size: contain, $position: right center);
                width: auto;
                padding-right: 150px;
                height: 250px;
                max-height: 100%;
                margin: calc(50vh - 200px) auto 0;
            }
        }
        &.fullWidth {
            width: 100% !important;
            min-width: 100% !important;
        }
    }
}
</style>

<!-- // PORTRAIT STYLES -->
<style lang="scss" scoped>
#content.register.portrait {
    overflow: hidden !important;
    padding: 8px;
    display: flex;
    flex-direction: column;

    .records-header {
        padding-bottom: 16px;
        height: 56px;

        .icon.list.selected {
            height: 40px;
            width: 40px;
        }
        .icon.search {
            height: 40px;
            width: 40px;
        }
    }

    .top-navbar {
        width: 100%;
        display: flex;
        align-items: center;
    }

    > .content {
        padding: 0px !important;
        height: 100% !important;
    }
    #column {
        width: 100%;
        max-width: 100%;
        margin: 0;
        background-color: initial;
        padding: 0px;
        box-shadow: none;
        border-radius: 0;
        height: calc(100% - 55px) !important;
    }
    .items {
        overflow: scroll;
        height: calc(100% - 63px);

        &.searching {
            height: calc(100% - 63px - 45px);
        }
    }

    .content.resume {
        min-width: 100% !important;
        margin-top: 0px !important;
    }
}
</style>
