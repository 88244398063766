<template>
    <div id="banner" :class="[$mq, type]">
        <div class="row">
            <div class="message" v-html="message"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    props: {
        type: {
            type: String,
            default: 'error'
        },
        message: {
            type: String,
            default: ''
        }
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss">
#banner {
    @include border-radius(2px);
    margin: 10px;
    width: calc(100% - 20px);
    background-color: $error-t80;
    border-left: 5px solid $error-t50;
    padding: 10px;
    min-height: 40px;

    .message {
        @include display-flex();
        @include align-items(center);
        @include background($image: img('lock_error-t50.svg'), $position: left 5px center, $size: 25px);
        @include font-size(sm);
        color: $error-s50;
        padding-left: 45px;
        min-height: 40px;
        font-family: $text;
        line-height: 18px;

        b {
            display: contents;
            color: $error-s50;
        }
    }

    &.portrait {
        margin: 10px auto;
        width: 100%;

        .message {
            @include font-size(xs);
        }
    }
}
</style>
