<template>
    <div class="audits-sections-and-items" :class="{ pageHasTabs: itemsWithActionPlansQuantity > 0, 'opened-info': infoIsOpen }">
        <!-- SECTIONS MENU BAR -->
        <div class="template-sections" v-if="sections.length > 1">
            <TemplateSections :isAudit="true" :sections="sections" @element-click="handleMenuBarSectionClick" />
        </div>

        <!-- SECTIONS AND ITEMS -->
        <div class="detail-box" :class="[{ 'with-sections': sections.length > 1, 'opened-info': infoIsOpen, pageHasTabs: itemsWithActionPlansQuantity > 0 }, 'hide-scrollbar']">
            <div class="exam performing" id="exam" :class="[{ 'hide-scrollbar': ['portrait'].includes($mq) }, { scrollbar: ['landscape', 'desktop'].includes($mq) }]">
                <div class="section minimize" v-if="!isActionPlan">
                    <span v-if="sectionsAreOpen" class="section-title" @click.self="toggleAllSections('collapse')">
                        {{ $t('audit.actions.collapse_all') }}
                    </span>
                    <span v-else class="section-title expand" @click.self="toggleAllSections('expand')">
                        {{ $t('audit.actions.expand_all') }}
                    </span>
                </div>

                <div v-if="check.description" class="check-description">
                    <div class="message keep-format" v-html="descriptionParse(check.description)" @click="view_more = !view_more"></div>
                </div>

                <div v-if="check.recover && editable && !check.complete_date" class="get-last-values">
                    <span class="message" @click="recoverValues()">{{ $t('tasks.get_last_values') }}</span>
                </div>

                <template v-if="check">
                    <div v-if="sections" v-for="section in sections" class="section" :class="{ closed: closedSections[section.section_id] }" :id="section.section_id">
                        <span
                            class="section-title"
                            :openThisSection="openThisSection(section.section_id)"
                            @click.self="toggleSection(section.section_id)"
                            :class="[
                                {
                                    'expand-full': !((templateConfig && templateConfig.show_score == undefined) || (templateConfig && templateConfig.show_score == 1 && valueInPercentage(section.completed, section.total_score) !== 0))
                                }
                            ]"
                            :id="section.name"
                        >
                            {{ section.name }}
                        </span>
                        <span class="section-score" :class="scoreClasses(section.score)" v-if="(templateConfig && templateConfig.show_score == undefined) || (templateConfig && templateConfig.show_score == 1 && valueInPercentage(section.completed, section.total_score) !== 0)">
                            <span class="percentage" v-if="valueInPercentage(section.completed, section.total_score) !== 0 && !isActionPlan">
                                {{ ' ' + valueInPercentage(section.completed, section.total_score) }}
                            </span>
                        </span>
                        <div class="section-content">
                            <template v-show="!closedSections[section.section_id]">
                                <answer v-for="item in section.items" :item="item" :list="check" :cId="check.id" :aId="item.item_id" :cType="type" :key="item.item_id" :showErrors="showErrors" :isActionPlanPage="isActionPlan" />
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import TemplateSections from '@/components/layout/TemplateSections'
import answer from '@/components/domain/tasks/answer'

export default {
    components: { TemplateSections, answer },
    name: 'SectionsAndItems',
    props: {
        sections: Array,
        check: Object,
        isActionPlan: Boolean,
        infoIsOpen: Boolean,
        templateConfig: Object,
        type: Number | String,
        showErrors: Boolean,
        itemsWithActionPlansQuantity: Number
    },
    data() {
        return {
            closedSections: {}
        }
    },
    computed: {
        sectionsAreOpen() {
            return Object.values(this.closedSections).filter((s) => s).length != Object.keys(this.check.sections).length
        }
    },
    methods: {
        valueInPercentage(value, total) {
            const result = Math.round((value / total) * 100)

            if (isNaN(result)) {
                return 0
            }

            return result + '%'
        },
        scoreClasses(value) {
            return {
                success: value == 100,
                progress: value > 75 && value < 100,
                warning: value > 0 && value <= 75,
                error: value == 0
            }
        },
        toggleSection(sec_id) {
            this.closedSections = {
                ...this.closedSections,
                [sec_id]: !this.closedSections[sec_id]
            }
        },
        openThisSection(sec_id) {
            if (this.openSection === true && this.closedSections[sec_id] !== false) {
                this.closedSections = {
                    ...this.closedSections,
                    [sec_id]: false
                }
            }
        },
        toggleAllSections(mode) {
            this.closedSections = Object.keys(this.closedSections).reduce((acc, key) => {
                acc[key] = mode == 'expand' ? false : true
                return acc
            }, {})
        },
        descriptionParse(msg) {
            if (msg.split(' ').length < 100) {
                return msgParse(msg)
            } else {
                if (this.view_more) {
                    return msgParse(msg) + ' <span class="more"> ' + this.$t('label.less') + '</span>'
                } else {
                    var maxLength = 150 // maximum number of characters to extract

                    var trimmedMsg = msg.substr(0, maxLength)

                    trimmedMsg = trimmedMsg.substr(0, Math.min(trimmedMsg.length, trimmedMsg.lastIndexOf(' ')))
                    return msgParse(trimmedMsg) + '... <span class="more">' + this.$t('label.more') + '</span>'
                }
            }
        },
        handleMenuBarSectionClick(section) {
            const sectionName = document.getElementById(section.name)
            sectionName.scrollIntoView(true, { behavior: 'smooth' })
        }
    },
    watch: {
        // to not show the "Finish" button in the Action Plan checklist
        isActionPlan() {
            if (this.check.type === 2 && this.check.status === 'progress') {
                if (this.isActionPlan) {
                    this.$bar.hideAction('save')
                } else {
                    this.$bar.addAction('save')
                }
            }
        }
    },
    mounted() {},
    created() {
        for (var k in this.check.sections) {
            var section = { ...this.check.sections[k] }
            this.closedSections[section.section_id] = false
        }
    }
}
</script>

<style lang="scss" scoped>
.audits-sections-and-items.pageHasTabs {
    width: 99%;
    @media (min-width: 901px) {
        top: 155px !important;
    }
    @media (max-width: 900px) {
        top: 150px !important;
    }

    @media (max-width: 788px) {
        top: 170px !important;
    }

    @media (max-width: 347px) {
        top: 215px !important;
    }
}
.audits-sections-and-items {
    width: 100%;
    position: absolute;
    top: 100px;
    overflow: auto !important;
    max-height: 100%;

    @media (max-width: 900px) {
        top: 80px;
    }

    @media (max-width: 488px) {
        top: 85px;
    }

    &.opened-info {
        top: 144px;

        @media (max-width: 403px) {
            top: 160px;
        }
    }

    .template-sections {
        width: 99%;
        height: 60px;
        position: sticky;
        top: -1px;
        z-index: 0;
        background-color: #f4f2ed;
        display: grid;
        place-items: center;

        .template-sections-container {
            width: 98%;
            max-width: 98%;
            overflow-x: hidden;
        }
    }

    .detail-box {
        // height: 70vh;
        // position: relative;
        // height: 100%;
        // max-height: 100%;
        // position: sticky;
        // height: 64vh;

        $sidebar-size: 40px;
        $collapse-button: 40px;
        $content-padding: 0px;
        $detail-bg: $default-bg-color;
        $detail-text-color: $main-dark;
        padding: 0;
        padding-left: $content-padding;
        width: 100%;
        overflow: auto;
        background-color: $detail-bg;

        &.pageHasTabs {
            height: 60vh !important;
        }

        .exam {
            @include border-radius($default-border-radius);
            padding: 8px 0;
            height: 100%;

            .minimize {
                opacity: 0.85;
                margin-bottom: 0px;
                margin-top: 0px;

                &:hover {
                    opacity: 1;
                }

                .section-title {
                    cursor: pointer;
                    @include font-size(s);

                    &.expand:before {
                        background-image: img('more_primary.svg');
                    }
                }
            }

            .expand:before {
                background-image: img('more_primary.svg');
            }

            .get-last-values {
                text-align: center;

                .message {
                    @include interaction();
                    @include border-radius($default-border-radius);
                    @include background($color: $main-t80, $image: img('download_primary.svg'), $position: right 12px center, $size: 22px);
                    @include font-size(m);
                    font-family: $text;
                    color: $main-dark;
                    padding: 12px 42px 12px 12px;
                    display: inline-block;

                    @include bgHover($main-t80);
                }
            }

            .section {
                $section-score-width: 80px;

                padding: 8px;
                // width: 94%;
                // margin: 12px auto;
                width: 100%;

                .section-title {
                    cursor: pointer;
                    @include font-size(s);
                    font-family: $text;
                    color: $main-dark;
                    display: inline-block;
                    width: calc(100% - #{$section-score-width});
                    margin-bottom: 4px;

                    &.expand-full {
                        width: 100%;
                    }

                    &:before {
                        @include background($color: $main-t80, $image: img('less_primary.svg'), $size: 10px);
                        @include border-radius(3px);
                        content: '';
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        margin-right: 6px;
                        margin-bottom: -2px;
                    }
                }
                .section-score {
                    display: inline-block;
                    width: $section-score-width;
                    text-align: right;

                    .points {
                        @include font-size(l);
                        font-family: $conden-bold;
                        color: $main;
                    }
                    .total {
                        @include font-size(sm);
                        font-family: $conden-bold;
                        color: $neutro-s90;
                        padding-right: 6px;
                    }
                    .percentage {
                        font-family: $text-medium;
                        color: $main;
                        background-color: #fff;
                        padding: 6px;
                        border-radius: 4px;
                        @include font-size(xs);
                        position: relative;
                        top: -2px;
                    }
                }

                .section-content {
                    @include display-flex();
                    @include flex-direction(row);
                    @include flex-wrap(wrap);
                    @include border-radius($default-border-radius);

                    @media (min-width: 901px) {
                        gap: 20px;
                        padding-top: 8px;
                    }

                    .question-score {
                        @include display-inline-flex();
                        // @include justify-content();
                        @include align-items();
                        @include font-size(m);
                        margin: 20px auto;
                        font-family: $text;
                        color: $main;
                        width: 80px;
                        height: 50px;
                    }
                }

                .section-total {
                    width: 100%;
                    text-align: right;

                    .text {
                        @include font-size(sm);
                        font-family: $text-bold;
                        color: $main-dark;
                    }
                    .total {
                        @include styScore();
                        @include font-size(ml);
                        font-family: $text-bold;
                        color: $main;
                        text-align: center;
                        display: inline-block;
                        width: 80px;
                        padding: 6px;
                    }
                }

                &.closed {
                    //height: 50px;
                    // margin: 12px auto;

                    .section-title:before {
                        background-image: img('more_primary.svg');
                    }

                    .section-content {
                        visibility: hidden;
                        display: none;
                        height: 0;
                        opacity: 0;

                        // .question, .question-score {
                        //   display: none;
                        // }
                    }
                }
            }
        }
    }

    .desktop {
        .detail-box {
            // height: calc(100% - 160px);
            // &.with-sections {
            //     height: calc(100% - 200px);

            //     &.opened-info {
            //         height: calc(100% - 255px);
            //     }
            // }

            // &.opened-info {
            //     height: calc(100% - 195px);
            // }

            .section {
                .section-title {
                    @include font-size(ml);
                }
            }
            .section-score {
                .percentage {
                    @include font-size(m, $important: true);
                }
            }
        }
    }

    .landscape {
        // Calcular la altura de todo el contenido top que es fijo para el listado
        .detail-box {
            // height: calc(100% - 140px);

            // &.with-sections {
            //     height: calc(100% - 200px);

            //     &.opened-info {
            //         height: calc(100% - 245px);
            //     }
            // }

            // &.opened-info {
            //     height: calc(100% - 175px);
            // }
            .section {
                .section-title {
                    @include font-size(ml);
                }
            }
            .section-score {
                .percentage {
                    @include font-size(m, $important: true);
                }
            }
        }
    }

    .exam {
        .section {
            width: 100%;

            $section-score-width: 80px;
            // margin: 8px auto;
            padding: 8px;

            .section-title {
                width: calc(100% - #{$section-score-width});
                @include font-size(xs);
                line-height: normal;
                &.expand-full {
                    width: 100%;
                }
            }

            .section-score {
                width: $section-score-width;
            }
        }
        .justify-description,
        .check-available {
            padding: 8px 20px 8px 50px;
            background-size: 25px;
            background-position: left 10px center;

            .message {
                @include font-size(xs);
                line-height: 1rem;
                width: calc(100% - 100px);
            }
            .action {
                width: 100px;
                @include font-size(xs);
                line-height: 0.75rem;
            }
        }
        .check-available {
            .message {
                width: 100%;
                max-width: 100%;
            }
        }
        .check-description {
            padding: 8px 20px 8px 34px;
            background-size: 25px;
            background-position: left 4px center;
            margin: 0px !important;

            .message {
                @include font-size(xs);
                line-height: 1rem;
            }
        }

        .get-last-values {
            .message {
                @include font-size(xs);
                line-height: normal;
            }
        }
    }

    @media (max-width: 767px) {
        .detail-box {
            // padding-top: 60px !important;
            background: transparent;
            // height: calc(100% - 175px);

            // &.opened-info {
            //     height: calc(100% - 220px);
            // }

            .message {
                padding-left: 10px;
            }

            // .exam {
            //     .section {
            //         width: 100%;

            //         $section-score-width: 80px;
            //         margin: 8px auto;

            //         .section-title {
            //             width: calc(100% - #{$section-score-width});
            //             @include font-size(xs);
            //             line-height: normal;
            //             &.expand-full {
            //                 width: 100%;
            //             }
            //         }

            //         .section-score {
            //             width: $section-score-width;
            //         }
            //     }
            //     .justify-description,
            //     .check-available {
            //         padding: 8px 20px 8px 50px;
            //         background-size: 25px;
            //         background-position: left 10px center;

            //         .message {
            //             @include font-size(xs);
            //             line-height: 1rem;
            //             width: calc(100% - 100px);
            //         }
            //         .action {
            //             width: 100px;
            //             @include font-size(xs);
            //             line-height: 0.75rem;
            //         }
            //     }
            //     .check-available {
            //         .message {
            //             width: 100%;
            //             max-width: 100%;
            //         }
            //     }
            //     .check-description {
            //         padding: 8px 20px 8px 34px;
            //         background-size: 25px;
            //         background-position: left 4px center;
            //         margin: 0px !important;

            //         .message {
            //             @include font-size(xs);
            //             line-height: 1rem;
            //         }
            //     }

            //     .get-last-values {
            //         .message {
            //             @include font-size(xs);
            //             line-height: normal;
            //         }
            //     }
            // }
            &.not-saved {
                // height: calc(100% - 115px);
                padding-bottom: 0px;
            }
            // &.opened-info {
            //     // height: calc(100% - 115px);
            //     padding-bottom: 0px;
            // }
            &.with-sections {
                // height: calc(100% - 205px);
                padding-bottom: 0px;

                // &.opened-info {
                //     height: calc(100% - 255px);
                // }
            }
        }
    }
}

// .pageHasTabs {
//     top: 180px !important;

//     @media (max-width: 400px) {
//         top: 200px !important;
//     }

//     @media (min-width: 698px) {
//         top: 160px !important;
//     }
// }
</style>
