<template>
    <div class="datatable-content">
        <table class="table" :class="[customClass, $mq]">
            <thead class="headers">
                <tr>
                    <th class="column" @click="sort(header.value)" v-if="header.visible" v-for="(header, header_index) in headers" :class="[{ 'col-icon': header.value == 'icon' }, { sortedDesc: columnSortedDesc == header.value }, { sortedAsc: columnSortedDesc && columnSortedAsc == header.value }, header.value, {'date-column': header.value === 'date' && ![$mq].includes('portrait')}]" :key="header_index">
                        {{ header.text }}
                    </th>
                </tr>
            </thead>

            <tbody class="table-values scrollbar">
                <tr class="values" v-for="(value, index) in values" :key="index" @click="sendAddedit(value.id)" v-if="!value.hide">
                    <td class="column-values" v-show="headerValue.visible" :data-type="values[index][headerValue.value + '_type']" :data-value="value[headerValue.value]" :class="[{ odd: index % 2 === 0 }, { even: index % 2 !== 0 }, { draft: value.statusB === 1 || (typeof value.statusB === 'undefined' && value.status === 0) }, {'sync-state': value.requireSync}, headerValue.value]" v-for="(headerValue, idx) in headers" :key="idx">
                        <!-- Condition for data-type === 5 -->
                        <template v-if="values[index][headerValue.value + '_type'] === 5 && value[headerValue.value] && isBase64(value[headerValue.value][0])">
                            <div class="column-values ">
                                <img v-if="value[headerValue.value]" :src="value[headerValue.value][0]" loading="lazy" />
                            </div>
                        </template>
                        <template v-else-if="values[index][headerValue.value + '_type'] === 5 && value[headerValue.value] && !isBase64(value[headerValue.value][0])">
                            <cld-image class="column-values" v-if="value[headerValue.value]" :publicId="value[headerValue.value][0]" type="fetch" loading="lazy">
                                <cld-transformation height="100" crop="fill" fetchFormat="webp" />
                            </cld-image>
                        </template>

                        <!-- If data-type is not 5, display the regular content using v-html -->
                        <template v-else-if="values[index][headerValue.value + '_type'] === 11">
                            <div class="selector-container">
                                <span v-if="getMultipleValues(value[headerValue.value + '_config'], value[headerValue.value])" class="selector-color" :style="{ background: getMultipleValues(value[headerValue.value + '_config'], value[headerValue.value]).c }" :class="[{ 'no-color': !getMultipleValues(value[headerValue.value + '_config'], value[headerValue.value]).c }]"></span>
                                <span class="selector-item">
                                    {{ value[headerValue.value] }}
                                </span>
                            </div>
                        </template>
                        <template v-else-if="values[index][headerValue.value + '_type'] !== 1">
                            <span v-if="values[index][headerValue.value + '_type'] !== 2 && values[index][headerValue.value + '_type'] !== 3" v-html="parseValue(value[headerValue.value], values[index][headerValue.value + '_type'])"></span>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    name: 'Datatable',
    props: {
        headers: {
            type: undefined
        },
        values: {
            type: Array
        },
        customClass: {
            type: undefined,
            default: false
        }
    },
    data() {
        return {
            columnSortedDesc: false,
            columnSortedAsc: false,
            FORMAT: FORMAT
        }
    },
    methods: {
        getMultipleValues(config, value) {
            return config.values.find(({ v }) => v === value)
        },
        parseValue(value, type) {
            if (type == FORMAT.PHOTO || type == FORMAT.SIGNATURE) {
                if (value && typeof value === 'object') {
                    var html = ''
                    Object.values(value).forEach((img) => {
                        html += '<img src="' + img + '"/>'
                    })
                    return value ? html : value
                } else {
                    return value ? '<img src="' + value + '"/>' : value
                }
            } else return value
        },

        sort(header) {
            if (!this.columnSortedDesc && !this.columnSortedAsc) {
                this.columnSortedDesc = header
            } else if (this.columnSortedDesc && this.columnSortedDesc != header) {
                this.columnSortedDesc = header
                this.columnSortedAsc = false
            } else if (this.columnSortedDesc && !this.columnSortedAsc && this.columnSortedDesc == header) {
                this.columnSortedAsc = header
            } else {
                this.columnSortedDesc = false
                this.columnSortedAsc = false
            }
            this.$emit('sort', { columnSortedDesc: this.columnSortedDesc, columnSortedAsc: this.columnSortedAsc })
        },
        isBase64(str) {
            const base64Regex = /^data:image\/[^;]+;base64[^"]+/i
            return base64Regex.test(str)
        },
        sendAddedit(id) {
            this.$emit('sendAddedit', id)
        }
    }
}
</script>

<style lang="scss" scoped>
.headers {
    tr {
        th.column {
            font-size: 12px;
        }
    }
}

.selector-container {
    width: 100%;
    height: 100%;
    padding: 8px;
    padding-right: 26px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include border-radius(3px);
    @include font-size(0.875rem);
    display: flex;
    align-items: center;
    gap: 5px;
    overflow-x: auto;

    .selector-color {
        padding: 5px;
        @include border-radius(100%);
        margin-left: 8px;

        &.no-color {
            border: 1px solid $color-selector2;
            position: relative;
        }
    }
}
</style>

<style lang="scss">
.audit {
    @include border-radius(5px);
    width: calc(100%);
    // margin-right: $alex-app-padding;
    overflow: auto;
    float: right;
    height: calc(100% - 65px);
    .headers {
        tr {
            th.column {
                // text-align: left;
                @include text-ellipsis();
            }
        }
    }
    .table-values {
        .values {
            .column-values {
                // text-align: left;

                .main-img {
                    height: 30px !important;
                }

                // EMPLOYEE
                .user-color {
                    @include display-flex();
                    @include align-items(center);
                    width: calc(100% - 10px);
                    height: 100%;
                    border-radius: unset;
                    top: -2px;
                    position: relative;
                    .main-img {
                        width: 30px;
                        height: 30px;
                        min-width: 30px;
                        min-height: 30px;
                    }
                    .name {
                        @include font-size($size: s, $important: true);
                        @include text-ellipsis();
                        height: fit-content;
                        padding-left: 4px;
                        overflow: visible;
                        position: relative;
                        width: 100%;
                        top: 0px;
                    }
                }
                // STATUS
                .status {
                    @include font-size(12px);
                    padding: 4px 4px;
                    border-radius: 3px;
                    position: relative;
                    top: -3px;
                    font-family: $text-bold;

                    &.completed {
                        background-color: $color-primary-700;
                        color: white;
                    }
                    &.progress {
                        // background-color: $color-info-500;
                        background-color: $color-info-500;  
                        color: white;
                    }
                    &.scheduled {
                        background-color: $color-neutral-500;
                        color: white;
                    }
                    &.tostart {
                        background-color: $color-neutral-500;
                        color: white;
                    }
                }
            }
        }
    }
    &.portrait {
        .table {
            min-width: 100% !important;
            width: min-content;

            .headers {
                tr {
                    th.column {
                        @include font-size(xs);
                        max-width: 300px;
                        min-width: 120px;
                        padding-top: 8px;
                        // padding-left: 0px;

                        &.simpleDate {
                            min-width: 50px;
                        }

                        &.auditory {
                            min-width: 180px;
                        }
                    }
                }
            }

            .table-values {
                .values {
                    // min-height: 75px;
                    height: 75px;

                    .column-values {
                        width: 30px;
                        min-width: 50px;

                        &.simpleDate {
                            font-family: $text-bold;
                        }

                        &.auditory {
                            @include background($image: img('right_neutro.svg'), $size: 12px, $position: right 10px center);
                            padding: 10px 10px 10px 12px;
                            line-height: 10px;
                            min-height: 75px;

                            // STATUS
                            .status {
                                @include font-size(12px);
                                // padding: 4px 5px;
                                padding: 8px 4px;
                                border-radius: 3px;
                                position: relative;
                                top: -3px;
                                text-transform: uppercase;
                                //font-family: $text-light;
                                font-family: $text-bold;

                                &.completed {
                                    background-color: #1d7672;
                                    color: white;
                                }
                                &.progress {
                                    // background-color: $color-info-500;
                                    background-color: #244390;
                                    color: white;
                                }
                                &.scheduled {
                                    background-color: $info;
                                    color: $main;
                                }
                            }

                            // SCORE
                            .total {
                                @include font-size($size: m, $important: true);
                                font-family: $text-bold;
                                color: $neutro-s80;
                                margin-left: 10px;
                            }

                            // EMPLOYEE
                            .user-color {
                                @include display-flex();
                                @include align-items(center);
                                width: calc(100% - 10px);
                                height: 100%;
                                padding-top: 17px;
                                .main-img {
                                    width: 30px;
                                    height: 30px;
                                    min-width: 30px;
                                    min-height: 30px;
                                }
                                .name {
                                    @include font-size($size: s, $important: true);
                                    @include text-ellipsis();
                                    overflow: visible;
                                    height: 12px;
                                    padding-left: 4px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

</style>
