<template>
    <div class="simple-nav-container">
        <button class="back-button" @click="handleButtonClick" :aria-label="$t('menu_aux.action.back')"></button>
        <div class="color-and-title">
            <div v-if="color" class="color" :style="{ backgroundColor: color }"></div>
            <h2 class="title">{{ title }}</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SimpleNavigationBar',
    data() {
        return {}
    },
    props: {
        color: {
            type: String,
            default: null
        },
        title: { type: String, default: '' }
    },
    computed: {},
    methods: {
        handleButtonClick() {
            this.$emit('back-button-click')
        }
    },
    created() {},
    mounted() {}
}
</script>

<style lang="scss" scoped>
.simple-nav-container {
    position: relative;
    display: flex;
    gap: 8px;
    width: 88%;

    .back-button {
        @include background($image: img('left_neutro.svg'), $size: 20px);
        @include border-radius(4px);
        background-color: #fff;
        width: 40px;
        height: 40px;
        min-height: 40px;
        min-width: 40px;
        cursor: pointer;

        &:hover {
            @include bgHover(#fff);
        }
    }

    .color-and-title {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 40px;
        width: calc(100% - 48px);
        // max-width: 100%;

        .color {
            width: 13px;
            height: 13px;
            min-height: 13px;
            min-width: 13px;
            border-radius: 50%;
        }

        h2 {
            margin: 0 !important;
            padding: 0 !important;
        }

        .title {
            font-family: $text-bold;
            font-size: 20px;
            color: $neutro-s80;
            @include text-ellipsis();
        }
    }
}
</style>
