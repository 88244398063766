<template class="action-plan-items-container">
    <section v-if="auditItem && actionPlanId && actionPlanItems" class="section-content">
        <!-- <answer v-for="item in actionPlanItems" :item="item" :list="list" :cId="actionPlanId" :aId="item.item_id" :cType="cType" :key="item.item_id" :showErrors="showErrors" :isActionPlanPage="isActionPlanPage" /> -->
        <answerCopy v-for="item in actionPlanItems" :item="item" :actionplans="actionplans" :list="list" :cId="actionPlanId" :aId="item.item_id" :cType="cType" :key="item.item_id" :showErrors="showErrors" :isActionPlanPage="isActionPlanPage" />
    </section>
</template>

<script>
import answerCopy from '@/components/domain/tasks/answerCopy.vue'

export default {
    name: 'ActionPlanItems',
    components: {
        // import answer this way to call it inside another answer component
        // answer: () => import('../tasks/answer.vue')
        answerCopy
    },
    props: {
        auditItem: { type: Object },
        list: { type: Object },
        actionplans: { type: Object },
        isIssueClosed: { type: Boolean, default: false },
        hasDependency_tpl: { type: [Boolean, Object], default: false },
        showErrors: { type: Boolean, default: false },
        isActionPlanPage: { type: Boolean, default: false }
    },
    data() {
        return {
            cType: 5 // Action Plan checklist type
        }
    },
    computed: {
        actionPlanId() {
            if (this.auditItem && this.auditItem.actionplans) {
                const actionPlansArr = Object.values(this.auditItem.actionplans)

                if (actionPlansArr.length > 0) {
                    return actionPlansArr[0].id
                }
            }
        },
        actionPlanItems() {
            if (this.auditItem) {
                const auditId = this.list.id
                const auditItemId = this.auditItem.item_id

                const actionPlanItems = this.$store.getters['audit/getActionPlanItems'](auditId, auditItemId, this.actionPlanId)

                return actionPlanItems
            }
        }
    },
    methots: {},
    watch: {},
    created() {},
    mounted() {}
}
</script>

<style lang="scss" scoped>
.action-plan-items-container {
    all: unset;

    .section-content {
        padding: 0px !important;
    }
}
</style>
