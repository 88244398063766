<template>
    <div class="item slideRight" :class="[itemClasses, $mq, {'require-sync': item.requireSync}]" :data-type="type" @click="sendId(item.id)">
        <div class="content">
            <div class="issue-content-top">
                <span v-if="item.issue_id" class="item-id">{{ item.issue_id }}</span>
                <div class="state">
                    <span class="issue-ball" :style="{ backgroundColor: issueStates ? issueStates[item.status].color : '' }"></span>
                    <span class="issue-state">{{ issueStates ? issueStates[item.status].name : '' }}</span>
                </div>
            </div>
            <span class="item-name">
                {{ item.title }}
            </span>
            <span class="item-time" v-if="item.created_date">
                <div class="hour">
                    {{ item.created_date | moment('ddd DD MMM HH:mm') }}
                </div>
                <!-- <div class="date">
                    {{ item.created_date | moment('HH:mm') }}
                </div> -->
                <div class="require-sync" v-if="item.requireSync"></div>
            </span>
            <span class="item-user" v-if="type != 1">
                <UserTag :userId="item.employee.id" size="medium" styleOf="block" :hasAvatar="true"></UserTag>
            </span>
        </div>
        <!-- <div class="resume">
            <span class="count" v-if="issueStates">
                <select v-if="issueStates" class="check-status" @change="changeState" disabled>
                    <option v-for="state in issueStates" :value="state.id" :selected="item.status == state.id">{{ state.name }}</option>
                </select>
                <select v-else class="check-status" disabled>
                    <option value="0" selected="true">{{ $t('issues.no_state') }}</option>
                </select>
            </span>
        </div> -->
        <div class="link"></div>
    </div>
</template>
<script>
import UserTag from '@/components/layout/UserTag'
export default {
    props: {
        item: { type: Object }
    },
    components: { UserTag },
    data() {
        return {
            type: 1
        }
    },
    computed: {
        itemClasses() {
            return {}
        },
        issueStates() {
            return this.$store.getters['getIssueStates']
        }
    },
    methods: {
        sendId(id) {
            this.$emit('sendId', id)
        },
        changeState(event) {
            if (typeof this.item !== 'undefined') {
                this.$store.dispatch('issues/update', {
                    check: this.gId,
                    status: event.target.value
                })
            }
        }
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : ''
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.item {
    $resume-width: rem(120px);
    $item-height: rem(65px);
    @include border-radius($default-border-radius);
    min-height: auto;
    height: auto;
    width: 100%;
    padding: 0;
    background-color: #fff;
    margin-bottom: 20px;
    // overflow: hidden;
    @extend .interaction;
    @include display-flex();
    @include align-items();

    &.require-sync {
        border-left: solid 4px $sync-main;
    }
    .content {
        @include display-flex();
        @include align-items();
        @include flex-direction(row);
        @include flex-wrap(wrap);
        float: left;
        width: calc(100% - 45px);
        height: auto;
        min-height: $item-height;
        padding: 10px;
        overflow: hidden;

        .issue-content-top {
            @include display-flex();
            padding-bottom: 6px;
            gap: 12px;
            .state {
                //padding-left: 16px;
                @include display-flex();
                @include align-items(center);
                .issue-ball {
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    margin-right: 5px;
                    min-width: 15px;
                    min-height: 15px;
                }
                .issue-state {
                    font-family: $text-light;
                    @include font-size(s);
                    color: $neutro-s60;
                    text-transform: uppercase;
                }
            }
        }
    }
    .resume {
        @include display-inline-flex();
        @include align-items();
        @include justify-content();
        float: left;
        // max-width: $resume-width;
        width: $resume-width;
        min-height: $item-height;
        height: 100%;
        .count {
            @include border-radius($default-border-radius);
            @include font-size(sm);
            font-family: $text-bold;
            background-color: $default-bg-color;
            width: auto;
            .check-status {
                @include text-ellipsis();
                @include font-size(inherit);
                width: auto;
                text-align: center;
                border: none;
                color: inherit;
                font-family: inherit;
                background-color: transparent;
                margin: 0;
                padding: 6px 9px;
            }
            .status-1 {
                color: $error;
            }
            .status-2 {
                color: $warning;
            }
            .status-5 {
                color: $done;
            }
        }
    }
    .item-id {
        @include border-radius(4px);
        padding: 4px 8px;
        background-color: $neutro-t10;
        @include font-size(sm);
        font-family: $text-light;
        display: inline-block;
        &:before {
            content: '#';
        }
    }
    .item-name {
        @include text-ellipsis($line: 3);
        @include font-size(m);
        width: 100%;
        height: auto;
        // max-height: calc(100% - 15px - 5px);
        margin-bottom: 0;
        font-family: $text-bold;
    }
    .item-time,
    .item-user {
        // width: 100%;
        // align-self: baseline;
        display: inline-block;
    }
    .item-time {
        @include background($image: img('input_datetime_cccccc.svg'), $size: 18px, $position: left 3px center);
        @include font-size(sm);
        font-family: $text-light;
        color: #666;
        text-transform: capitalize;
        margin-top: 4px;
        padding-left: 30px;
        //width: rem(175px);
        @include display-flex();
        @include align-items(center);
        height: 25px;

        .hour {
            @include font-size(s);
            display: inline-block;
            font-family: $text;
            color: $neutro-s50;
            margin-right: 5px;
            text-transform: uppercase;
        }
        .require-sync{
            width: 20px;
            height: 20px;
            @include background($image: img('offline/sync_icon.svg'))
        }
    }
    .item-user {
        // @include background( $image: img('user_cccccc.svg'), $size: 14px, $position: left 3px center );
        font-family: $text-light;
        margin-top: 3px;
        padding-left: 12px;
        width: calc(100% - #{rem(175px)});
    }
    .link {
        @include opacity(0.2);
        @include background($image: img('right_dark.svg'), $size: 20px);
        width: rem(40px);
        min-height: $item-height;
        height: 100%;
        display: inline-block;
        clear: both;
    }
    .checklist-chart {
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline;
        height: 3px;
        z-index: 1;
        background-color: #eee;
        width: 100%;
        overflow: auto;
        .done {
            background-color: $done;
            height: 3px;
        }
        .progress {
            background-color: $progress;
            height: 3px;
        }
        .main {
            background-color: $main-t40;
        }
        .sec {
            background-color: $main-t40;
        }
        .complem {
            background-color: $accent-t40;
        }
        .future {
        }
        .intime {
        }
        .past {
        }
    }
}
</style>
<style lang="scss">
.item.portrait {
    padding: 5px;
    position: relative;
    margin: unset;
    min-height: auto;
    .content {
        background-color: #fff !important;
        display: block;
        width: calc(100% - 40px);
        max-width: 100%;
        padding: 5px !important;
        .item-id {
            height: 30px;
            @include font-size(xs);
        }
        .item-name {
            width: 100%;
            @include text-ellipsis($line: 3);
            @include font-size(s);
            width: 100%;
            height: auto;
            // height: 25px;

            // portrait-tablet
        }
        @include portrait-tablet {
            @include font-size(sm);
            .item-id {
                @include font-size(s);
            }
        }
        .state {
            .issue-state {
                @include font-size(xs);
                width: 100%;
            }
        }
        .item-time {
            width: 100%;
            @include font-size(xs);
        }
        .item-user {
            width: 100%;
            padding-left: 0;
            @include font-size(xs);
            font-size: $text-light;
            .name {
                @include font-size(xs, $important: true);
            }
        }
    }
    .resume {
        display: block;
        height: auto;
        min-height: auto;
        width: calc(100% - 40px);
        .count {
            @include font-size(xs);
            padding: 5px 10px;
        }
    }
    .link {
        width: 40px;
    }
}
</style>
