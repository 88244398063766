<template>
    <div class="item" :class="[itemClasses, $mq]" :data-type="type" @click="sendId(item.id)" v-if="show">
        <template v-if="['portrait'].includes($mq)">
            <div class="content-item" :class="[$mq]">
                <div class="category-container" v-if="allCategories && item.category_id && categories[item.category_id]">
                    <div class="category-color" :style="{ backgroundColor: categories[item.category_id] ? categories[item.category_id].color : '' }"></div>

                    <span class="category-name">{{ categories[item.category_id] ? categories[item.category_id].name : '' }}</span>
                </div>
                <span class="item-name">{{ item.title }}</span>
                <div class="resume">
                    <span class="item-info warning" v-if="item.need_justification && !item.justify_id">{{ $t('answer.justify.pending') }}</span>
                    <span class="item-info warning done" v-if="item.need_justification && item.justify_id">{{ $t('answer.justify.justified') }}</span>

                    <span class="item-info error" v-if="!item.need_justification && item.important && stillAvailable">{{ $t('tasks.list_important') }}</span>
                    <span class="count">{{ item.completed + '/' + item.total }}</span>
                    <span class="issues" v-if="issuesCount()">{{ issuesCount() }}</span>
                    <span class="to-review" v-if="item.to_review > 0">{{ item.to_review }}</span>
                    <span class="out-schedule" v-if="showOutSchedule" :class="{ out_time: !item.complete_date && !item.in_time, complete_out_time: item.complete_date && !item.completed_in_time }"></span>
                </div>
            </div>

            <div class="checklist-chart">
                <div class="done" :class="itemClasses" :style="{ width: (item.completed / item.total) * 100 + '%' }"></div>
            </div>

            <div class="link"></div>
        </template>
        <template v-else>
            <div class="content-item">
                <div class="left-content">
                    <div class="category-container" v-if="allCategories && item.category_id && categories[item.category_id]">
                        <div class="category-color" :style="{ backgroundColor: categories[item.category_id] ? categories[item.category_id].color : '' }"></div>

                        <span class="category-name">{{ categories[item.category_id].name }}</span>
                    </div>
                    <span class="item-name">{{ item.title }}</span>
                </div>
            </div>

            <div class="info-container">
                <div class="resume">
                    <span class="item-info warning" v-if="item.need_justification && !item.justify_id && item.completed != item.total">{{ $t('answer.justify.pending') }}</span>
                    <span class="item-info warning done" v-if="item.need_justification && item.justify_id">{{ $t('answer.justify.justified') }}</span>
                    <span class="item-info error" v-if="!item.need_justification && item.important && stillAvailable">{{ $t('tasks.list_important') }}</span>
                    <span class="out-schedule" v-if="showOutSchedule" :class="{ out_time: !item.complete_date && !item.in_time, complete_out_time: item.complete_date && !item.completed_in_time }"></span>
                    <span class="issues" v-if="issuesCount()">{{ issuesCount() }}</span>
                    <span class="to-review" v-if="item.to_review > 0">{{ item.to_review }}</span>
                    <span class="count">{{ item.completed + '/' + item.total }}</span>
                </div>
                <div class="link"></div>
            </div>

            <div class="checklist-chart">
                <div class="done" :class="itemClasses" :style="{ width: done == 100 ? 100 + '%' : (item.completed / item.total) * 100 + '%' }"></div>
            </div>
        </template>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    props: {
        allCategories: {
            type: Boolean,
            default: false
        },
        item: { type: Object }
    },
    data() {
        return {
            type: 1,
            show: true
        }
    },
    computed: {
        categories() {
            var items = this.$store.getters['tasks/getCategories']()
            // sort by id = 'all first'
            return items
        },
        printDate() {
            var start = moment(this.item.schedule.start)
            var end = moment(this.item.schedule.end)

            if (start.isSame(end)) {
                return this.$t('tasks.all_day')
            } else {
                return this.$tc('assets.timing.from', 2) + ' ' + start.format('HH:mm ddd') + ' ' + this.$tc('assets.timing.to', 2) + ' ' + end.format('HH:mm ddd')
            }
        },
        done() {
            // return done if item total is 0 and item completed is 0
            if (this.item && this.item.completed == 0 && this.item.total == 0) {
                return 100
            }
            return parseInt((this.item.completed / this.item.total) * 100)
        },
        stillAvailable() {
            let now = moment()
            let limit = this.item.limit
            let schedule = this.item.schedule

            if (now.isBetween(limit.start, limit.end) && !now.isBetween(schedule.start, schedule.end) && now.isAfter(schedule.end)) {
                return true
            }
            return false
        },

        itemClasses() {
            var now = moment()
            var start = moment(this.item.schedule.start)
            var end = moment(this.item.schedule.end)

            return {
                done: this.done == 100,
                justified: this.done != 100 && this.item.need_justification,
                progress: this.done != 100,
                pending: this.item.need_justification,
                future: start.isAfter(now),
                past: end.isSameOrBefore(now),
                intime: start.isSameOrBefore(now) && end.isSameOrAfter(now)
            }
        },

        showOutSchedule() {
            if (this.item.need_justification) return false
            return (this.item.current_day && !this.item.complete_date && !this.item.in_time) || (this.item.complete_date && !this.item.completed_in_time)
        }
    },
    methods: {
        sendId(id) {
            this.$emit('sendId', id)
        },
        issuesCount() {
            var items = this.item.items
            let count = 0
            if (items) {
                for (var item in items) {
                    let idx = items[item]
                    if (idx.alerts) {
                        var alerts = idx.alerts
                        if (alerts.issues && alerts.issues.length > 0) {
                            count += alerts.issues.length
                        }
                    }
                }
            }
            return count
        }
    },
    created() {
        const itemsToExclude = [FORMAT.TITLE, FORMAT.SUBTITLE]
        const filteredItems = Object.values(this.item.items).filter((item) => !itemsToExclude.includes(parseInt(item.type)))

        if (filteredItems.length == 0) {
            this.show = false
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.item {
    $resume-width: 100px;
    $item-height: rem(65px);

    @include display-flex();
    justify-content: space-between;
    @include align-items();
    @include interaction();
    @include border-radius($default-border-radius $default-border-radius 0 0);
    min-height: $item-height;
    height: auto;
    width: 100%;
    padding: 0;
    position: relative;
    background-color: #fff;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    @include bgHover(#fff);

    .content-item {
        @include display-inline-flex();
        @include align-items();
        // width: calc(100% - #{$resume-width});
        // width: calc(100% - 153px);
        // max-width: calc(100% - 55px - #{$resume-width});
        min-height: $item-height;
        height: auto;
        padding: 20px;
        overflow: hidden;
        float: left;
        // padding-bottom: 20px;

        .left-content {
            @include display-flex();
            @include flex-direction(column);
            width: 100%;

            .category-container {
                @include display-flex();
                @include align-items(center);
                width: 100%;
                height: auto;
                padding-bottom: 4px;
                .category-color {
                    width: 15px;
                    height: 15px;
                    min-height: 13px;
                    min-width: 13px;
                    border-radius: 50%;
                }

                .category-name {
                    @include font-size(sm);
                    font-family: $text-light;
                    color: $neutro-s60;
                    padding-left: 6px;
                    text-transform: uppercase;
                }
            }
        }

        .item-name {
            @include display-inline-flex();
            @include align-items();
            // @include text-ellipsis();
            @include font-size(ml);
            width: 100% !important;
            height: 100%;
            margin-bottom: 0;
            font-family: $text-bold;
        }

        .item-time {
            @include font-size(sm);
            width: 100%;
            padding: 0;
            font-family: $text-light;
            margin: 3px 0 8px 0;
            color: #777;
            display: block;

            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    .info-container {
        padding: 0.5em;
        display: flex;
    }

    .resume {
        @include display-flex();
        @include align-items(center);
        @include justify-content(flex-end);
        gap: 0.38em;
        float: left;
        height: $item-height;

        .count {
            @include border-radius($default-border-radius);
            @include font-size(m);
            font-family: $text-bold;
            background-color: $neutro-t10;
            padding: 6px;
            height: 30px;
            display: grid;
                place-content: center;
        }
        .out-schedule {
            @include background($image: img('clock.svg'), $size: 16px, $position: center);
            @include border-radius($default-border-radius);
            @include font-size(20px);
            font-family: $text-bold;
            color: $warning;
            padding-left: 30px;
            height: 30px;
            &.out_time {
                @include background($image: img('clock_animated.svg'), $size: 30px, $position: center);
            }
            &.complete_out_time {
                background-color: $color-success-500;
            }
        }
        .issues {
            @include background($image: img('alert_warn.svg'), $size: 20px, $position: left 6px center);
            @include border-radius($default-border-radius);
            @include font-size(20px);
            font-family: $text-bold;
            background-color: $warning-t80;
            padding: 6px;
            color: $warning;
            padding-left: 30px;
            height: 30px;
        }
        .item-info {
            @include border-radius($default-border-radius);
            @include font-size(s);
            font-family: $conden-bold;
            background-color: $main;
            color: #fff;
            padding: 6px;
            width: 80px;
            height: 32px;
            text-align: center;

            &.done {
                background-color: tint($main, 75%);
                color: darken($main, 25%);
            }

            &.warning {
                background-color: $warning;
                color: #fff;
                &.done {
                    background-color: tint($warning, 75%);
                    color: darken($warning, 25%);
                }
            }

            &.error {
                background-color: $error;
                color: #fff;
                &.done {
                    background-color: tint($error, 75%);
                    color: darken($error, 25%);
                }
            }
        }

        .to-review {
            font-family: 'Avenir-Bold';
            background-image: img('icons/double_check_white.svg');
            background-repeat: no-repeat;
            background-size: 17px;
            background-position: left 6px center;
            background-color: #ffc700;
            @include font-size(m);
            color: #ffffff;
            border-radius: 0.3125rem;
            padding: 6px 7px 6px 27px;
            height: 30px;
            display: grid;
                place-content: center;
        }
    }

    .link {
        @include opacity(0.2);
        @include background($image: img('right_dark.svg'), $size: 20px);
        width: 50px;
        height: $item-height;
        display: inline-block;
        clear: both;
    }

    .checklist-chart {
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline;
        height: 4px;
        z-index: 1;
        background-color: $neutro-t10;
        width: 100%;
        overflow: auto;
        @include border-radius(0 0 2px 2px);

        .done {
            background-color: $done;
            height: 4px;
        }
        .progress {
            background-color: $progress;
            height: 4px;
        }
        .justified {
            background-color: $warning;
        }
        .main {
            background-color: $main-t40;
        }
        .complem {
            background-color: $accent-t40;
        }
        // .future {
        // }
        // .intime {
        // }
        // .past {
        // }
    }

    &.pending {
        .item-name {
            width: calc(100% - 85px);
        }
    }

    @media (max-width: 900px) {
        // @include small {
        @include flex-wrap(wrap);
        padding: 8px;

        .content-item,
        .resume,
        .link {
            height: auto;
            min-height: 40px;
        }

        .content-item {
            @include display-flex();
            @include align-items(flex-start);
            width: 100%;
            max-width: 100%;
            padding: 0;
            padding-bottom: 12px;

            .item-name {
                @include font-size(m);
                word-break: break-word;
            }
        }
        .resume {
            @include justify-content(flex-start);
            width: calc(100% - 50px);
            .item-info {
                @include font-size(xs);
                padding: 3px 6px;
            }
        }
        // .link {
        // }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.item.portrait {
    margin-bottom: 10px;

    .content-item {
        display: block;
        padding: 8px !important;

        .item-name {
            @include font-size(sm);
            width: calc(100% - 40px) !important;
            display: block;
            margin-bottom: 10px;
        }

        .category-container {
            @include display-flex();
            @include align-items(center);
            width: 100%;
            height: auto;
            .category-color {
                width: 13px;
                height: 13px;
                min-height: 13px;
                min-width: 13px;
                border-radius: 50%;
            }

            .category-name {
                @include font-size(sm);
                font-family: $text-light;
                color: $neutro-s60;
                padding-left: 6px;
                text-transform: uppercase;
                width: 500px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &.portrait {
            .category-name {
                @include font-size(xs);
            }
        }
        .resume {
            width: auto;
            height: 30px;
            min-height: auto;
            // margin-top: 5px;

            display: flex !important;
            @include align-items(center);
            @include justify-content(flex-end);
            gap: 0.38em;

            .count {
                @include font-size(s);
                display: grid;
                place-content: center;
            }
            .issues {
                @include font-size(s);
                padding-left: 32px;
            }
            .item-info {
                padding: 5px 6px;
                @include font-size(xs);
            }

            .to-review {
                @include font-size(s);
                background-size: 0.875rem;
                                display: grid;
                place-content: center;
            }
        }
    }
    .link {
        position: absolute;
        right: 0;
        top: auto;
    }
    // .checklist-chart {
    // }
}
</style>
