<template>
    <div id="content" class="issues" :class="{ detail: detail, portrait: $mq.includes('portrait') }">
        <!-- MAIN -->
        <template v-if="['portrait'].includes($mq) && !detail">
            <div class="header incidents-header" v-if="!detail">
                <!-- ON HIGHLIGHT -->
                <template v-if="!selectedState">
                    <div class="icon list" v-if="Object.values(issueStates).length > 0" :class="{ selected: !searchBar }" @click="displayCategories()"></div>
                    <div class="icon search" @click="searchSwitch()" :class="{ selected: searchBar }"></div>
                </template>
                <!-- ON A CATEGORY -->
                <div v-else-if="selectedState" class="back category" @click="selectedState = false">
                    <div class="ball" :style="{ backgroundColor: selectedState ? selectedState.color : '' }"></div>
                    <div class="name" v-if="selectedState" :class="{ all: selectedState == issueStates['all'] }">
                        {{ selectedState.name + ' ' }}
                        {{ (selectedState.type == 1 || selectedState.type == 2) && numberOpenIssues[selectedState.id] ? '(' + numberOpenIssues[selectedState.id] + ')' : selectedState.type == 3 ? '' : '(0)' }}
                    </div>
                </div>
            </div>
            <div class="search-container" v-if="searchBar">
                <div class="calendar-navigation-container">
                    <calendar-navigation @select="selectDate" :selected_date="formatDateForCalendarNavigation()" :future_days="1" :past_days="pastDays"></calendar-navigation>
                    <Collapse-transition>
                        <div class="filter-container" v-if="showFilters">
                            <select class="filter-date" v-model="templateSelected" @focus="handleSelect('open')" @focusout="handleSelect('close')">
                                <option v-for="item in templates" :key="item.id" :value="item.id">{{ item.name }}</option>
                            </select>
                            <div class="search-btn" :class="{ disabled: templateSelected === previousTemplateSelected }" @click="selectDate('search')">
                                {{ $t('menu_aux.action.search') }}
                            </div>
                        </div>
                    </Collapse-transition>
                </div>
                <div class="show-filters">
                    <div class="show-filter" @click="showFilters = !showFilters">
                        {{ !showFilters ? $t('issues.show_filters') : $t('issues.hide_filters') }}
                    </div>
                </div>
            </div>
            <div class="list-container hide-scrollbar" v-if="!selectedState && !searchBar">
                <div class="title-issues">{{ $t('issues.open_issues') }}</div>
                <div class="open-issues">
                    <div class="card-container" v-for="(state, index) in issueStates" :key="index" v-if="state.type == 1 || state.type == 2">
                        <div class="card" @click="selectState(state)">
                            <div class="left-card">
                                <div class="ball" :style="{ backgroundColor: state.color }"></div>
                                <div class="name">
                                    {{ state.name }}
                                </div>
                            </div>
                            <div class="right-card">
                                <div class="number" v-if="Object.values(numberOpenIssues).length > 0">
                                    {{ numberOpenIssues[state.id] ? numberOpenIssues[state.id] : 0 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title-issues">{{ $t('issues.closed_issues') }}</div>
                <div class="closed-issues">
                    <div class="card-container" v-for="(state, index) in issueStates" :key="index" v-if="state.type == 3">
                        <div class="card" @click="selectState(state)">
                            <div class="left-card">
                                <div class="ball" :style="{ backgroundColor: state.color }"></div>
                                <div class="name">
                                    {{ state.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="issues-list hide-scrollbar" id="issueList" :class="[{ searchOpened: searchBar }, { filterOpened: showFilters }]" v-if="selectedState || searchBar">
                <template v-if="selectedState && typeof allIssues[selectedState.id] != 'undefined' && Object.values(allIssues[selectedState.id]).length > 0">
                    <item v-for="(issue, idx) in allIssues[selectedState.id]" :key="idx" :item="issue" :cType="type" @sendId="openDetail"></item>
                    <div class="pagination" v-if="number_page > 1 || number_items * number_page < results">
                        <div v-if="number_page > 1" class="item prev" @click="page_prev_state()"></div>
                        <div v-if="number_items * number_page < results" class="item next" @click="page_next_state()"></div>
                    </div>
                </template>
                <template v-else-if="searchBar && Object.values(searchBarIssues).length > 0">
                    <item v-for="(issue, idx) in searchBarIssues" :key="idx" :item="issue" :cType="type" @sendId="openDetail"></item>
                    <div class="pagination" v-if="number_page > 1 || number_items * number_page < results">
                        <div v-if="number_page > 1" class="item prev" @click="page_prev()"></div>
                        <div v-if="number_items * number_page < results" class="item next" @click="page_next()"></div>
                    </div>
                </template>
                <template v-else>
                    <EmptyPage :title="$t('empty_pages.issues_title')" type="issues" :buttonText="$t('empty_pages.create_issue')" @buttonAction="createIssue" :description="$t('empty_pages.issues_description')"></EmptyPage>
                </template>
            </div>
        </template>

        <div v-if="!detail && !['portrait'].includes($mq)" class="column left hide-scrollbar">
            <!-- <p class="title">{{ $t('issues.filter_title') }}</p> -->
            <div class="header" v-if="!detail">
                <!-- ON HIGHLIGHT -->
                <div class="icon list" v-if="Object.values(issueStates).length > 0" :class="{ selected: !searchBar }" @click="displayCategories()"></div>
                <div class="icon search" @click="searchSwitch()" :class="{ selected: searchBar }"></div>
            </div>

            <div class="list-container hide-scrollbar">
                <div class="title-issues">{{ $t('issues.open_issues') }} </div>
                <div class="open-issues">
                    <div class="card-container landscape" v-for="(state, index) in issueStates" :key="index" v-if="state.type == 1 || state.type == 2">
                        <div class="card" @click="selectState(state)" :class="{ selected: state.id === selectedState.id }">
                            <div class="left-card">
                                <div class="ball" :style="{ backgroundColor: state.color }"></div>
                                <div class="name">
                                    {{ state.name }}
                                </div>
                            </div>
                            <div class="right-card">
                                <div class="number" v-if="Object.values(numberOpenIssues).length > 0">
                                    {{ numberOpenIssues[state.id] ? numberOpenIssues[state.id] : 0 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title-issues">{{ $t('issues.closed_issues') }}</div>
                <div class="closed-issues">
                    <div class="card-container landscape" v-for="(state, index) in issueStates" :key="index" v-if="state.type == 3">
                        <div class="card" @click="selectState(state)" :class="{ selected: state.id === selectedState.id }">
                            <div class="left-card">
                                <div class="ball" :style="{ backgroundColor: state.color }"></div>
                                <div class="name">
                                    {{ state.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!detail && !['portrait'].includes($mq)" class="content right hide-scrollbar" id="issueList">
            <div class="contain">
                <div class="calendar-navigation-container" v-if="searchBar">
                    <calendar-navigation @select="selectDate" :selected_date="formatDateForCalendarNavigation()" :future_days="1" :past_days="pastDays"></calendar-navigation>
                    <CollapseTransition>
                        <div class="filter-container" v-if="showFilters">
                            <select class="filter-date" v-model="templateSelected" @focus="handleSelect('open')" @focusout="handleSelect('close')">
                                <option v-for="item in templates" :key="item.id" :value="item.id">{{ item.name }}</option>
                            </select>
                            <div class="search-btn" :class="{ disabled: templateSelected === previousTemplateSelected }" @click="selectDate('search')">
                                {{ $t('menu_aux.action.search') }}
                            </div>
                        </div>
                    </CollapseTransition>
                    <div class="show-filters">
                        <div class="show-filter" @click="showFilters = !showFilters">
                            {{ !showFilters ? $t('issues.show_filters') : $t('issues.hide_filters') }}
                        </div>
                    </div>
                </div>

                <template v-if="selectedState && allIssues[selectedState.id] && Object.values(allIssues[selectedState.id]).length > 0">
                    <item v-for="(issue, idx) in allIssues[selectedState.id]" :key="idx" :item="issue" :cType="type" @sendId="openDetail"></item>
                    <div class="pagination" v-if="checkConnetion && (number_page > 1 || number_items * number_page < results)">
                        <div v-if="number_page > 1" class="item prev" @click="page_prev_state()"></div>
                        <div v-if="number_items * number_page < results" class="item next" @click="page_next_state()"></div>
                    </div>
                </template>
                <template v-else-if="searchBar && Object.values(searchBarIssues).length > 0">
                    <item v-for="(issue, idx) in searchBarIssues" :key="idx" :item="issue" :cType="type" @sendId="openDetail"></item>
                    <div class="pagination" v-if="checkConnetion && (number_page > 1 || number_items * number_page < results)">
                        <div v-if="number_page > 1" class="item prev" @click="page_prev()"></div>
                        <div v-if="number_items * number_page < results" class="item next" @click="page_next()"></div>
                    </div>
                </template>
                <template v-else-if="!connection && (Object.values(searchBarIssues).length == 0 || (selectedState && allIssues[selectedState.id] && Object.values(allIssues[selectedState.id]).length == 0)) && !itemsLoaded">
                    <ConnectionBox gType="issuesConnection" @reload="load"></ConnectionBox>
                </template>
                <template v-else-if="searchBar && Object.values(searchBarIssues).length == 0">
                    <EmptyPage :title="$t('empty_pages.issues_title')" type="issues" :description="$t('empty_pages.issues_no_searched')"></EmptyPage>
                </template>
                <template v-else>
                    <EmptyPage :title="$t('empty_pages.issues_title')" type="issues" :buttonText="$t('empty_pages.create_issue')" @buttonAction="createIssue" :description="$t('empty_pages.issues_description')"></EmptyPage>
                </template>
            </div>
        </div>

        <!-- DETAIL -->
        <detail v-if="detail" ref="detailIssue" :gId="detail" :cType="type" @changedState="resetItems" :permissions="detailtPermissions()"></detail>
    </div>
</template>

<script>
import EmptyPage from '../components/layout/EmptyPage.vue'
import item from '@/components/domain/issues/item'
import detail from '@/components/domain/issues/detail'
import CalendarNavigation from '@/components/layout/CalendarNavigation'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import ConnectionBox from '@/components/domain/ui/Box.vue'

export default {
    name: 'Issues',
    components: { item, detail, EmptyPage, CalendarNavigation, CollapseTransition, ConnectionBox },
    created() {
        this.$bar.reset()
        let self = this

        Object.keys(self.issueStates).map(function (item_id, i) {
            var item = self.issueStates[item_id]
            if (item.default == 1) {
                self.showByState.push(item.id)
            }
        })

        if (Object.values(self.issueStates).length > 0) {
            if (self.$mq != 'portrait' && !self.selectedState) {
                self.selectedState = Object.values(self.issueStates).find((item) => item.type == 1)
            }
        }
        let promises = []
        if (Object.values(this.issueStates).length > 0) {
            //    make all petitions of issues/loadall with every issuestate
            Object.values(this.issueStates).forEach((item) => {
                if (item.type != '3' && item.id != this.selectedState.id) {
                    promises.push(
                        this.$store.dispatch('issues/loadAll', {
                            date: 'all',
                            type: item.id,
                            template_id: 'all',
                            number_page: self.number_page,
                            number_items: self.number_items,
                            noRefresh: self.$route.params.noRefresh
                        })
                    )
                }
            })
        }
        Promise.all(promises).then((values) => {
            self.load(true)
        })
    },
    data() {
        return {
            type: 4,
            route_name: 'IssuesDetail',
            route: '/issues',
            detail: this.$route.params.id,
            name: this.$route.params.name,
            showByState: [],
            tmp_date_selected: false,
            searchBar: false,
            selectedState: false,
            pastDays: 2,
            date: 'all',
            number_items: '10',
            number_page: 1,
            templateSelected: 'all',
            previousTemplateSelected: 'all',
            showFilters: false,
            results: 0,
            numberOpenIssues: !fnCheckConnection() ? this.$store.getters['issues/getNumberOpenIssues'] : [],
            searchBarIssues: [],
            itemsLoaded: false
        }
    },
    computed: {
        checkConnetion() {
            return this.$store.getters['getConnState']
        },
        connection() {
            return this.$store.getters['getConnState']
        },
        def() {
            return this.$store.getters['issues/getDefaults']
        },
        issuesWithTempId() {
            return this.$store.getters['issues/getItemsWithTemporalId']
        },
        dateSelector() {
            return this.$store.getters['getDateSelector']
        },
        issues() {
            return this.$store.getters['issues/getAll']
        },
        allIssues() {
            var items = this.$store.getters['issues/getAllItems']
            //{1: {1: {}, 2: {}}, 2: {1: {}, 2: {}}}
            const result = {}
            const rolEmployee = this.$store.getters['employee/getRol'](this.currentEmployee().rol)
            //Comprobar que los items de los issues tengan roles
            for (const [key, value] of Object.entries(items)) {
                for (const [key2, value2] of Object.entries(value)) {
                    if (value2.config && value2.config.nRoles) {
                        const allowRoles = value2.config.nRoles //[{roleId: 1}, {roleId: 2}, {roleId: 3}]
                        if (allowRoles.length > 0) {
                            const allowRolesIds = allowRoles.map((role) => role.roleId)
                            if (allowRolesIds.includes(this.currentEmployee().rol)) {
                                if (!result[key]) {
                                    result[key] = {}
                                }
                                result[key][key2] = value2
                            }
                        }

                        if (rolEmployee.level >= 8) {
                            if (!result[key]) {
                                result[key] = {}
                            }
                            result[key][key2] = value2
                        }
                    } else if (value2.config && value2.config.roles) {
                        const allowRoles = value2.config.roles
                        if (allowRoles.includes(this.currentEmployee().rol)) {
                            if (!result[key]) {
                                result[key] = {}
                            }
                            result[key][key2] = value2
                        }

                        if (rolEmployee.level >= 8) {
                            if (!result[key]) {
                                result[key] = {}
                            }
                            result[key][key2] = value2
                        }
                    } else {
                        if (!result[key]) {
                            result[key] = {}
                        }
                        result[key][key2] = value2
                    }
                }
            }
            return result
        },
        templates() {
            return this.$store.getters['issues/getTemplatesCopy']
        },
        filteredIssues() {
            if (this.selectedState && this.selectedState.id) {
                return Object.values(this.issues).filter((issue) => issue.status == this.selectedState.id)
            }
        },
        /** Adjust Alphabetical Order by name field in Issues State @hom669 */
        issueStates() {
            let states = this.$store.getters['getIssueStates']
            let sortedStates = states ? Object.values(states).sort((state1, state2) => state1.name.localeCompare(state2.name)) : [];
            return sortedStates
        },
        userLogged() {
            var user = this.$store.getters['loginUser/getLocalEmployee']
            var roleUserLogged = this.$store.getters['employee/getEmployee'](user)
            return roleUserLogged
        },
        userAppAccess() {
            return this.$store.getters['loginUser/getHasAccessApp']
        },

        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        }
    },
    watch: {
        $mq: function (newVal, oldVal) {
            if (newVal == 'landscape' || newVal == 'desktop') {
                this.selectedState = Object.values(this.issueStates)[0]
                this.load(true)
            }
        },
        $route: 'load',
        //Ver la ruta si tiene un temporal id, para cambiarlo por un id de temporal_id
        issues(newVal, oldVal) {
            if (this.issuesWithTempId.length > 0 && this.$route.params.id) {
                const routerEdit = this.$route.params.id
                log('>> router', this.issuesWithTempId, routerEdit)
                this.issuesWithTempId.forEach((issue) => {
                    if (issue.temporal_id == routerEdit) {
                        this.detail = issue.id
                        this.$router.push({ name: 'IssuesDetail', params: { id: issue.id, noRefresh: true } })
                    }
                })
            }
        },
        selectedState: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.number_page = 1
                this.load(true)
            }
        },
    },
    methods: {
        detailtPermissions() {
            if (this.detail) {
                log('$$ id detailtPermissions', this.detail)
                log('$$ id detailtcehckPermissions', this.checkPermissions(this.detail))
                return this.checkPermissions(this.detail)
            }
        },
        checkPermissions(id) {
            log('$$ id checkPermissions', id)
            const template = this.$store.getters['issues/getById'](id)
            log('$$ id checkPermissions', this.templates)

            const userRole = this.$store.getters['employee/getRol'](this.user.rol)
            if (userRole.level >= 8) {
                return true
            }

            if (template && template.config && !(template.config.nRoles || template.config.roles)) {
                return true
            }

            if (template && template.config && template.config.nRoles && template.config.nRoles.length > 0) {
                const templateRoles = template.config.nRoles
                let isValid = false

                templateRoles.forEach((role) => {
                    if (role.roleId == userRole.id && role.permissions.includes('w')) {
                        isValid = true
                        return
                    }
                })

                return isValid
            }

            return false
        },
        formatDateForCalendarNavigation() {
            var date = this.date
            if (date == 'today') {
                return moment().format('YYYY-MM-DD')
            } else if (date == 'yesterday') {
                return moment().subtract(1, 'days').format('YYYY-MM-DD')
            } else if (date == 'week') {
                return moment().subtract(1, 'weeks').format('YYYY-MM-DD')
            } else if (date == 'month') {
                return moment().subtract(1, 'months').format('YYYY-MM-DD')
            } else if (date == 'quarter') {
                return moment().subtract(3, 'months').format('YYYY-MM-DD')
            } else {
                return moment(date).format('YYYY-MM-DD')
            }
        },
        page_prev() {
            this.search = ''
            this.number_page -= 1
            this.filter()
            document.getElementById('issueList').scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        page_next() {
            this.search = ''
            this.number_page += 1
            this.filter()
            document.getElementById('issueList').scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        filter() {
            if (fnCheckConnection()) {
                this.selectDate()
            }
        },
        page_prev_state() {
            this.search = ''
            this.number_page -= 1
            this.filter_state()
            document.getElementById('issueList').scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        page_next_state() {
            this.search = ''
            this.number_page += 1
            this.filter_state()
            document.getElementById('issueList').scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        filter_state() {
            if (fnCheckConnection()) {
                this.load(true)
            }
        },
        loadCategories() {
            this.$store.dispatch('issues/loadCategories')
        },
        selectDate(date) {
            var self = this
            let dateCopy = date && date != 'search' ? date : false
            if (typeof dateCopy != 'undefined' && dateCopy && moment(dateCopy).format('YYYY-MM-DD') != self.date) {
                self.number_page = 1
            }
            if (date && date != 'search') {
                this.date = moment(date).set({ minutes: 0, seconds: 0 }).format('YYYY-MM-DD')
            } else if (date == 'search') {
                self.number_page = 1
            }

            self.$store
                .dispatch('issues/loadAll', {
                    date: self.date,
                    type: false,
                    template_id: self.templateSelected,
                    number_page: self.number_page,
                    number_items: 10,
                    noRefresh: self.$route.params.noRefresh
                })
                .then(function (response) {
                    self.previousTemplateSelected = self.templateSelected
                    self.numberOpenIssues = response.opened_issues_total_items
                    self.results = response.total_items
                    if (self.templateSelected == 'all') {
                        self.searchBarIssues = response.data
                    } else {
                        self.searchBarIssues = Object.values(response.data)
                    }
                })
        },
        selectState(state) {
            if (state != this.selectedState) {
                this.number_page = 1
            }
            this.selectedState = state
            if (this.searchBar) {
                this.searchSwitch()
            }
            if (this.numberOpenIssues && this.numberOpenIssues[state.id]) {
                this.results = this.numberOpenIssues[state.id]
            }
            if (this.allIssues && !this.allIssues[state.id]) {
                this.load(true)
            }
        },
        resetItems() {
            if (fnCheckConnection()) {
                this.$store.commit('issues/resetAllItems')
            }
        },
        searchSwitch() {
            var self = this
            self.number_page = 1
            if (!this.searchBar) {
                this.searchBar = true
                if (Object.values(this.templates).length == 0) {
                    this.loadCategories()
                }
                this.date = moment().format('YYYY-MM-DD')
                this.selectedState = false

                self.$store
                    .dispatch('issues/loadAll', {
                        date: self.date,
                        type: false,
                        template_id: self.templateSelected,
                        number_page: self.number_page,
                        number_items: 10,
                        noRefresh: self.$route.params.noRefresh
                    })
                    .then(function (response) {
                        self.searchBarIssues = response.data
                        self.results = response.total_items
                    })
                // this.selectedState = this.issueStates['all']
            } else {
                this.searchBar = false
            }
        },
        displayCategories() {
            this.search = ''
            this.searchBar = false
            if (this.$mq == 'landscape' || this.$mq == 'desktop') {
                this.selectedState = Object.values(this.issueStates)[0]
            }
        },
        handleSelect(mode) {
            if (mode == 'open') {
                this.tmp_date_selected = this.date
                this.date = false
            } else {
                this.date = this.date ? this.date : this.tmp_date_selected
                this.tmp_date_selected = false
            }
        },
        createIssue() {
            var self = this
            if (!self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                self.$popup.login({
                    list: self.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function (response) {
                        if (response) {
                            self.$router.push({ name: self.route_name })
                            self.$popup.close()
                        }
                    }
                })
            } else {
                let data = self.$store.getters['issues/getTemplates']()
                const rolEmployee = self.$store.getters['employee/getRol'](self.currentEmployee().rol)
                data = Object.values(data).filter((item) => {
                    if (item.config && item.config.nRoles) {
                        const rolesAllowed = item.config.nRoles //[{roleId: 1}, {roleId: 2}, {roleId: 3}]
                        if (rolesAllowed.length > 0) {
                            let isValid = false
                            rolesAllowed.forEach((role) => {
                                if (role.roleId == self.currentEmployee().rol && role.permissions.includes('w')) {
                                    isValid = true
                                    return
                                }
                            })
                            if (isValid) {
                                return item
                            }
                        }

                        if (rolEmployee.level >= 8) {
                            return item
                        }
                    } else if (item.config && item.config.roles) {
                        //Si el usuario tiene rol = o superior al que se necesita para crear la incidencia
                        const allowRoles = item.config.roles
                        if (allowRoles.includes(self.currentEmployee().rol)) {
                            return item
                        }
                        if (rolEmployee.level >= 8) {
                            return item
                        }
                    } else {
                        return item
                    }
                })

                self.$popup.select({
                    list: Object.values(data),
                    cType: self.type,
                    title: self.$t('popup.add_template.title'),
                    listEmpty: self.$t('popup.add_template.list_empty'),
                    textSave: self.$t('popup.add_template.text_save'),
                    textSaveStart: self.$t('popup.add_template.text_save_start'),
                    callSave: function (pSelf, store) {
                        self.$overlay.loading()
                        if (typeof pSelf.selectedEl !== 'undefined') {
                            return store
                                .dispatch('issues/add', {
                                    tpl_id: pSelf.selectedEl,
                                    cType: self.type
                                })
                                .then(function (response) {
                                    if (response) {
                                        self.setAskTitle = true
                                        pSelf.selectedEl = response.id
                                    } else {
                                        self.$popup.close()
                                    }
                                    return Promise.resolve(pSelf, store)
                                })
                        } else {
                            return Promise.resolve(pSelf, store)
                        }
                    },
                    callSaveStart: function (pSelf, store) {
                        self.$overlay.loading()
                        self.$router.push({
                            name: self.route_name,
                            params: { id: pSelf.selectedEl }
                        })

                        return Promise.resolve(pSelf, store)
                    }
                })
            }
        },

        openDetail(id) {
            this.$router.push({ name: this.route_name, params: { id: id } })
        },

        itemClasses() {
            return ''
        },
        handleDetailLogic(refresh) {
            var self = this
            self.$store.commit('issues/filterByState', { states: self.showByState })

            if (refresh) {
                if (self.$route.params.id == undefined) {
                    self.detail = false
                } else if (self.$route.params.id != undefined) {
                    if (!self.$store.getters['issues/getById'](self.$route.params.id)) {
                        self.$store
                            .dispatch('issues/loadById', {
                                id: self.$route.params.id,
                                noRefresh: self.$route.params.noRefresh
                            })
                            .then(function () {
                                self.detail = self.$route.params.id
                                if (typeof self.$refs.detailIssue !== 'undefined') {
                                    self.$refs.detailIssue.checkAllMandatory(true)
                                }
                            })
                    } else {
                        self.detail = self.$route.params.id
                    }
                }
            }
            if (!self.detail) {
                self.barActionsMain()
            }
            self.$overlay.hide()
        },
        load(refresh) {
            this.itemsLoaded = false
            var self = this
            if (self.$route.params.noRefresh) {
                return
            }

            if (fnCheckConnection()) {
                self.$overlay.loading()

                // If we are filtering by states and we aren't at detail
                if (!self.searchBar) {
                    self.$store
                        .dispatch('issues/loadAll', {
                            date: 'all',
                            type: self.selectedState.id,
                            template_id: 'all',
                            number_page: self.number_page,
                            number_items: self.number_items,
                            noRefresh: self.$route.params.noRefresh
                        })
                        .then(function (response) {
                            self.numberOpenIssues = response.opened_issues_total_items
                            self.results = response.total_items
                            self.handleDetailLogic(refresh)
                        })
                } else {
                    self.selectDate(self.date)
                    self.handleDetailLogic(refresh)
                }
                this.itemsLoaded = true
            } else {
                self.$overlay.loading()
                self.handleDetailLogic(refresh)
            }
            this.$store.dispatch('issues/loadNumberOpenIssues')
            if(this.numberOpenIssues && !fnCheckConnection()){
                if(typeof this.numberOpenIssues == 'object' && Object.keys(this.numberOpenIssues).length <= 0){
                    this.numberOpenIssues = this.$store.getters['issues/getNumberOpenIssues']
                }else{
                    this.numberOpenIssues = this.$store.getters['issues/getNumberOpenIssues']
                }
            }
        },

        barActionsMain() {
            var self = this
            if (this.userLogged && this.userAppAccess) {
                self.$bar.reset()
                self.$bar.show()
                const label = this.$t('menu_aux.action.create')
                self.$bar.addAction('create', {
                    callback: function () {
                        if (!self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                            self.$popup.login({
                                list: self.$store.getters['employee/getLoginUsers'](1),
                                callAfterAccess: function (response) {
                                    if (response) {
                                        self.$router.push({ name: self.route_name })
                                        self.$popup.close()
                                    }
                                }
                            })
                        } else {
                            let data = self.$store.getters['issues/getTemplates']()
                            const rolEmployee = self.$store.getters['employee/getRol'](self.currentEmployee().rol)
                            data = Object.values(data).filter((item) => {
                                if (item.config && item.config.nRoles) {
                                    const rolesAllowed = item.config.nRoles //[{roleId: 1}, {roleId: 2}, {roleId: 3}]
                                    if (rolesAllowed.length > 0) {
                                        let isValid = false
                                        rolesAllowed.forEach((role) => {
                                            if (role.roleId == self.currentEmployee().rol && role.permissions.includes('w')) {
                                                isValid = true
                                                return
                                            }
                                        })
                                        if (isValid) {
                                            return item
                                        }
                                    }

                                    if (rolEmployee.level >= 8) {
                                        return item
                                    }
                                } else if (item.config && item.config.roles) {
                                    //Si el usuario tiene rol = o superior al que se necesita para crear la incidencia
                                    const allowRoles = item.config.roles
                                    if (allowRoles.includes(self.currentEmployee().rol)) {
                                        return item
                                    }
                                    if (rolEmployee.level >= 8) {
                                        return item
                                    }
                                } else {
                                    return item
                                }
                            })

                            self.$popup.select({
                                list: Object.values(data),
                                cType: self.type,
                                title: self.$t('popup.add_template.title'),
                                listEmpty: self.$t('popup.add_template.list_empty'),
                                textSave: self.$t('popup.add_template.text_save'),
                                textSaveStart: self.$t('popup.add_template.text_save_start'),
                                callSave: function (pSelf, store) {
                                    self.$overlay.loading()
                                    if (typeof pSelf.selectedEl !== 'undefined') {
                                        return store
                                            .dispatch('issues/add', {
                                                tpl_id: pSelf.selectedEl,
                                                cType: self.type
                                            })
                                            .then(function (response) {
                                                if (response) {
                                                    self.setAskTitle = true
                                                    pSelf.selectedEl = response.id
                                                } else {
                                                    self.$popup.close()
                                                }
                                                return Promise.resolve(pSelf, store)
                                            })
                                    } else {
                                        return Promise.resolve(pSelf, store)
                                    }
                                },
                                callSaveStart: function (pSelf, store) {
                                    self.$overlay.loading()
                                    self.$router.push({
                                        name: self.route_name,
                                        params: { id: pSelf.selectedEl }
                                    })

                                    return Promise.resolve(pSelf, store)
                                }
                            })
                        }
                    },
                    label: label
                })
            }
        },
        today() {
            var x = new Date()
            var y = x.getFullYear().toString()
            var m = (x.getMonth() + 1).toString()
            var d = x.getDate().toString()
            // d.length == 1 && (d = "0" + d);
            // m.length == 1 && (m = "0" + m);
            var yyyymmdd = y + '/' + m + '/' + d
            return yyyymmdd
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        }
    },
    filters: {
        hour: function (value) {
            if (value == parseInt(value, 10)) {
                return moment('00:00', 'HH:mm').add(value, 'hours').format('HH')
            } else {
                return moment('00:00', 'HH:mm').add(value, 'hours').format('HH:mm')
            }
        }
    },
    mounted() {},
    beforeDestroy() {
        this.$bar.hide()
    },
    updated() {},
    beforeDestroy() {
        this.resetItems()
    }
}
</script>

<style lang="scss">
#content.issues {
    $column-width: 20%;
    $content-width: 70%;

    > .content {
        $content-margin: 12px;

        @include border-radius($default-border-radius);
        background-color: $default-bg-color !important;
        // margin: $content-margin 0 0 0;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;
        height: calc(100% - #{$content-margin}) !important;
        border: none;
        padding: 8px !important;

        &.empty {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-end);
            width: 100% !important;

            .message {
                @include display-inline-flex();
                @include align-items();
                @include background($image: img('alexpose_04.svg'), $size: contain, $position: right center);
                width: 100%;
                padding-right: 150px;
                height: 250px;
                max-height: 100%;
            }
        }

        .total-items {
            @include font-size(s);
            font-family: $text-light;
            color: #666;
            font-family: $text;
            // text-transform: uppercase;
            width: 100%;
            margin-bottom: 6px;
        }

        .contain {
            width: 100%;
            padding: 0px 20px 10px 20px;
            height: 100%;

            .calendar-navigation-container {
                .filter-container {
                    @include display-flex();
                    @include justify-content(space-between);
                    @include align-items(center);
                    .filter-date {
                        @include display-flex();
                        @include background($image: img('filter_neutro.svg'), $size: 24px, $position: left 10px center);
                        @include font-size(m);
                        @include border-radius(5px);
                        height: 40px;
                        width: 79%;
                        padding-left: 40px;
                        font-family: $text;
                        color: $neutro-s80;
                        margin-top: 8px;
                        margin-bottom: 8px;
                        box-shadow: none;
                        border: none;
                    }

                    .search-btn {
                        @include display-flex();
                        @include align-items(center);
                        @include justify-content(center);
                        @include border-radius(4px);
                        @include font-size(ml);
                        color: #fff;
                        font-family: $text-bold;
                        background-color: $main;
                        height: 40px;
                        width: 20%;
                        cursor: pointer;

                        &.disabled {
                            opacity: 0.6;
                            cursor: not-allowed;
                        }
                    }
                }
                .show-filters {
                    @include display-flex();
                    @include justify-content(center);
                    @include align-items(center);
                    padding-top: 12px;
                    padding-bottom: 12px;
                    cursor: pointer;

                    .show-filter {
                        @include background($image: img('filter_elabel.svg'), $size: 25px, $position: left center);
                        @include font-size(sm);
                        font-family: $text-light;
                        padding-left: 40px;
                    }
                }
            }

            .empty {
                @include empty();
                @include background($size: 70px, $position: top center);
                padding-top: 80px;
                margin-top: calc(40% - #{$menu-top-height} - 70px);
                background-image: img('confeti_light.svg');
            }
            .pagination {
                height: 50px;
                margin-bottom: 55px;
            }
        }
    }

    > .column.left {
        @include display-flex();
        @include flex-direction(column);
        width: auto;
        max-width: 320px;
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(159, 162, 166, 0.25);
        border-radius: 8px;
        height: calc(100% - 24px);
        overflow: hidden;

        .header {
            width: 100%;
            height: 60px;

            .icon {
                &.list {
                    top: 10px;
                    left: 10px;
                    &:hover:not(.selected) {
                        @include bgHover(#fff);
                    }
                }

                &.search {
                    top: 10px;
                    right: 10px;
                    //  &hover but not selected class
                    &:hover:not(.selected) {
                        @include bgHover(#fff);
                    }
                }
            }
        }

        .title {
            @include font-size(sm, $important: true);
            color: #444;
            font-family: $text !important;
            text-align: center;
        }

        .select-date {
            margin: 6px auto 12px auto;
            padding: 0;
            width: 90%;
        }

        .issues-container {
            position: absolute;
            width: 100%;
            margin-top: 16px;

            .title {
                @include font-size(sm, $important: true);
                color: $main-text;
                text-align: center;
                font-family: $text;
                margin: 12px 0;
            }

            .select-date {
                @include display-flex();
                @include font-size(s);
                font-family: $text !important;
                color: #777 !important;
                line-height: rem(22px);
                height: 46px;
                border-bottom: none;
                width: 85%;
                margin: 0 auto;
                text-align: center;
                -webkit-appearance: auto;

                option {
                    font-family: $text;
                }
            }

            .btn {
                @include background($color: transparent, $image: img('unchecked_aaaaaa.svg'), $size: 18px, $position: left 6px center);
                @include border-left-radius(3px);
                @include font-size(m);
                line-height: rem(22px);
                color: #777;
                font-family: $conden;
                display: block;
                width: calc(85% - 10px);
                margin: 12px 10px 12px auto;
                padding: 9px 12px;
                text-align: left;
                transition-duration: 0.1s;
                padding-left: 32px;

                &.selected {
                    @include default-box-shadow();
                    @include background($color: $default-bg-color, $image: img('checked_primary.svg'), $size: 18px, $position: left 6px center);
                    width: 85%;
                    margin: 12px -3px 12px auto;
                    clip-path: inset(-5px 2px -5px -5px);
                }
            }

            .btn.today {
                // border-color: #eee;
            }
        }

        .list-container {
            width: 100%;
            height: calc(100% - 60px);
            overflow-y: scroll;
            .title-issues {
                @include font-size(sm);
                color: $neutro-s60;
                text-transform: uppercase;
                font-family: $text-light;
                margin: 12px 0;
                padding: 0 10px;
            }
            .open-issues,
            .closed-issues {
                @include display-flex();
                @include flex-direction(column);
                gap: 10px;
            }
            .card-container {
                gap: 10px;
                .card {
                    @include display-flex();
                    @include justify-content(space-between);
                    @include border-radius(5px);
                    @include align-items(center);
                    background-color: #fff;
                    padding: 20px;
                    height: 55px;
                    // arrow-right
                    @include background($image: img('right_neutro.svg'), $size: 15px, $position: right 10px center);
                    cursor: pointer;

                    .left-card {
                        @include display-flex();
                        @include align-items(center);

                        .ball {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                        .name {
                            width: calc(100% - 26px);
                            @include font-size(sm);
                            color: $neutro-s90;
                            font-family: $text-bold;
                        }
                    }
                    .right-card {
                        .number {
                            @include font-size(xl);
                            color: $neutro-s90;
                            font-family: $text-bold;
                        }
                        margin-right: 20px;
                    }
                    &:hover {
                        @include bgHover(#fff);
                    }
                }
                &.landscape {
                    padding-left: 8px;
                    .card {
                        background: none;

                        .right-card {
                            margin-right: 2px;
                        }

                        &.selected {
                            border-radius: 4px 0 0 4px;
                            background-color: $main;

                            .left-card {
                                .name {
                                    color: #fff;
                                }
                            }
                            .right-card {
                                .number {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.issues.portrait {
    padding: 8px;
    overflow: hidden !important;

    .icon {
        height: 40px;
        width: 40px;
        max-height: 40px;
        max-width: 40px;
    }

    .incidents-header {
        height: 56px;
        padding-bottom: 16px;
    }

    .header {
        @include align-items(start);
    }

    .search-container {
        padding-bottom: 6px;
        .calendar-navigation-container {
            .filter-date {
                @include display-flex();
                @include background($image: img('filter_neutro.svg'), $size: 24px, $position: left 10px center);
                @include font-size(m);
                @include border-radius(5px);
                height: 45px;
                width: 100%;
                padding-left: 40px;
                font-family: $text;
                color: $neutro-s80;
                margin-top: 8px;
                margin-bottom: 8px;
                box-shadow: none;
                border: none;
            }
            .search-btn {
                @include display-flex();
                @include align-items(center);
                @include justify-content(center);
                @include border-radius(4px);
                @include font-size(m);
                color: #fff;
                font-family: $text-bold;
                background-color: $main-dark;
                height: 50px;
                cursor: pointer;

                // portrait-tablet
                @include portrait-tablet {
                    @include font-size(ml);
                }
                &.disabled {
                    opacity: 0.6;
                    cursor: not-allowed;
                }
            }
        }
        .show-filters {
            @include display-flex();
            @include justify-content(center);
            @include align-items(center);
            padding-top: 12px;
            padding-bottom: 12px;
            cursor: pointer;

            .show-filter {
                @include background($image: img('filter_elabel.svg'), $size: 25px, $position: left center);
                @include font-size(s);
                font-family: $text-light;
                padding-left: 40px;
            }
        }
    }
    .issues-list {
        width: 100%;
        overflow-y: auto;
        height: calc(100% - 120px);
        @include display-flex();
        @include flex-direction(column);
        gap: 10px;

        &.searchOpened {
            height: calc(100% - 180px);

            &.filterOpened {
                height: calc(100% - 310px);
            }
        }
    }

    .list-container {
        width: 100%;
        height: calc(100% - 125px);
        overflow-y: auto;
        .title-issues {
            @include font-size(xs);
            color: $neutro-s60;
            text-transform: uppercase;
            font-family: $text-light;
            padding: 8px 0;
        }
        // portrait-tablet
        @include portrait-tablet {
            .title-issues {
                @include font-size(s);
            }
        }
        .open-issues,
        .closed-issues {
            @include display-flex();
            @include flex-direction(column);
            gap: 10px;
        }
        .card-container {
            gap: 10px;
            .card {
                @include display-flex();
                @include justify-content(space-between);
                @include border-radius(5px);
                @include align-items(center);
                background-color: #fff;
                padding: 20px;
                height: 55px;
                // arrow-right
                @include background($image: img('right_neutro.svg'), $size: 12px, $position: right 10px center);
                cursor: pointer;

                .left-card {
                    @include display-flex();
                    @include align-items(center);

                    .ball {
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .name {
                        @include font-size(s);
                        color: $neutro-s90;
                        font-family: $text-bold;
                        width: calc(100% - 26px);
                    }
                    // portrait-tablet
                    @include portrait-tablet {
                        .name {
                            @include font-size(sm);
                        }
                    }
                }
                .right-card {
                    margin-right: 20px;
                    .number {
                        @include font-size(l);
                        color: $neutro-s90;
                        font-family: $text-bold;
                    }
                    // portrait-tablet
                    @include portrait-tablet {
                        margin-right: 40px;
                        .number {
                            @include font-size(xl);
                        }
                    }
                }
                &:hover {
                    @include bgHover(#fff);
                }
            }
            // portrait-tablet
            @include portrait-tablet {
                .card {
                    @include background($image: img('right_neutro.svg'), $size: 15px, $position: right 10px center);
                }
            }
        }
    }

    .column {
        width: 100% !important;
        height: auto !important;
        max-width: 100% !important;
        margin-top: 0;

        .title {
            display: none;
        }

        .select-date {
            margin: 0%;
            width: 100%;
            padding: 0 10px;
            font-family: $text-light;
            text-align: left;
            -webkit-appearance: auto;
        }
        .issues-container {
            position: relative;
            padding: 5px 0px;
            margin-top: 0px;

            .btn {
                width: 100%;
                @include font-size(s);
                background-color: #fff;
                box-shadow: none;
                margin: 5px 0;
                font-family: $text-light;
            }
        }
    }
    .content.right {
        width: 100% !important;
        box-shadow: none;
        overflow: hidden;
        padding-bottom: 50px !important;
        height: auto !important;

        .contain {
            width: 100%;
        }
    }
}
</style>
