<template>
    <div v-if="gType == 'checklistConnection' || gType == 'registerConnection' || gType == 'chatConnection' || gType == 'sensorConnection' || gType == 'libraryConnection' || gType == 'issuesConnection'">
        <div class="main-container-box" :class="[typeOfBox.class, { portrait: ['portrait'].includes($mq) }]">
            <div class="content-box">
                <div class="offline-image"></div>
                <!-- <img :src="getURLImage('andy-surprise.svg')" /> -->
                <div class="title">{{ typeOfBox.title }}</div>
                <div class="subtitle">
                    <p>{{ typeOfBox.subtitle }}</p>
                    <p>{{ typeOfBox.text }}</p>
                </div>
            </div>
            <div class="button-container">
                <button :class="typeOfBox.class" @click="reload">{{ typeOfBox.buttonText }}</button>
            </div>
        </div>
    </div>
    <div v-else-if="gType == 'connection'">
        <div class="main-container-box labels" :class="[typeOfBox.class, { portrait: ['portrait'].includes($mq) }]">
            <div class="content-box">
                <div class="title">{{ typeOfBox.title }}</div>
                <div class="subtitle">{{ typeOfBox.subtitle }}</div>
            </div>
            <div class="button-container">
                <button :class="typeOfBox.class" @click="reload">{{ typeOfBox.buttonText }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
export default {
    name: 'Box',

    props: {
        gType: {
            type: String,
            default: 'default'
        }
    },

    data() {
        return {
            typeOfBox: {
                default: {
                    class: 'default',
                    title: 'Verifica tu conexion',
                    subtitle: 'No se ha podido cargar los ultimos valores',
                    buttonText: 'Reintentar'
                },
                connection: {
                    class: 'connection',
                    title: this.translation('ui.box.connection.title') ? this.translation('ui.box.connection.title') : 'Verifica tu conexion',
                    subtitle: this.translation('ui.box.connection.subtitle') ? this.translation('ui.box.connection.subtitle') : 'No se ha podido cargar los ultimos valores',
                    buttonText: this.translation('ui.box.connection.buttonText') ? this.translation('ui.box.connection.buttonText') : 'Reintentar'
                },
                checklistConnection: {
                    class: 'checklist',
                    title: this.translation('ui.box.checklistConnection.title') ? this.translation('ui.box.checklistConnection.title') : 'Verifica tu conexion',
                    subtitle: this.translation('ui.box.checklistConnection.subtitle') ? this.translation('ui.box.checklistConnection.subtitle') : 'Parece que las tareas no se cargaron.',
                    text: this.translation('ui.box.checklistConnection.text') ? this.translation('ui.box.checklistConnection.text') : '¡Inténtelo de nuevo!',
                    buttonText: this.translation('ui.box.checklistConnection.buttonText') ? this.translation('ui.box.checklistConnection.buttonText') : 'Reintentar'
                },
                registerConnection: {
                    class: 'checklist',
                    title: this.translation('ui.box.checklistConnection.title') ? this.translation('ui.box.checklistConnection.title') : 'Verifica tu conexion',
                    subtitle: this.translation('ui.box.registerConnection.subtitle') ? this.translation('ui.box.registerConnection.subtitle') : 'Parece que los registros no se cargaron.',
                    text: this.translation('ui.box.checklistConnection.text') ? this.translation('ui.box.checklistConnection.text') : '¡Inténtelo de nuevo!',
                    buttonText: this.translation('ui.box.checklistConnection.buttonText') ? this.translation('ui.box.checklistConnection.buttonText') : 'Reintentar'
                },
                issuesConnection: {
                    class: 'checklist',
                    title: this.translation('ui.box.checklistConnection.title') ? this.translation('ui.box.checklistConnection.title') : 'Verifica tu conexion',
                    subtitle: this.translation('ui.box.issuesConnection.subtitle') ? this.translation('ui.box.issuesConnection.subtitle') : 'Parece que las incidencias no se cargaron.',
                    text: this.translation('ui.box.checklistConnection.text') ? this.translation('ui.box.checklistConnection.text') : '¡Inténtelo de nuevo!',
                    buttonText: this.translation('ui.box.checklistConnection.buttonText') ? this.translation('ui.box.checklistConnection.buttonText') : 'Reintentar'
                },
                chatConnection: {
                    class: 'checklist',
                    title: this.translation('ui.box.checklistConnection.title') ? this.translation('ui.box.checklistConnection.title') : 'Verifica tu conexion',
                    subtitle: this.translation('ui.box.chatConnection.subtitle') ? this.translation('ui.box.chatConnection.subtitle') : 'Parece que las notas no se cargaron.',
                    text: this.translation('ui.box.checklistConnection.text') ? this.translation('ui.box.checklistConnection.text') : '¡Inténtelo de nuevo!',
                    buttonText: this.translation('ui.box.checklistConnection.buttonText') ? this.translation('ui.box.checklistConnection.buttonText') : 'Reintentar'
                },
                sensorConnection: {
                    class: 'checklist',
                    title: this.translation('ui.box.checklistConnection.title') ? this.translation('ui.box.checklistConnection.title') : 'Verifica tu conexion',
                    subtitle: this.translation('ui.box.sensorConnection.subtitle') ? this.translation('ui.box.sensorConnection.subtitle') : 'Parece que los sensores no se cargaron.',
                    text: this.translation('ui.box.checklistConnection.text') ? this.translation('ui.box.checklistConnection.text') : '¡Inténtelo de nuevo!',
                    buttonText: this.translation('ui.box.checklistConnection.buttonText') ? this.translation('ui.box.checklistConnection.buttonText') : 'Reintentar'
                },
                libraryConnection: {
                    class: 'checklist',
                    title: this.translation('ui.box.checklistConnection.title') ? this.translation('ui.box.checklistConnection.title') : 'Verifica tu conexion',
                    subtitle: this.translation('ui.box.libraryConnection.subtitle') ? this.translation('ui.box.libraryConnection.subtitle') : 'Parece que los documentos no se cargaron.',
                    text: this.translation('ui.box.checklistConnection.text') ? this.translation('ui.box.checklistConnection.text') : '¡Inténtelo de nuevo!',
                    buttonText: this.translation('ui.box.checklistConnection.buttonText') ? this.translation('ui.box.checklistConnection.buttonText') : 'Reintentar'
                }
            }
        }
    },

    methods: {
        reload() {
            this.$emit('reload')
        },
        translation(translation) {
            return i18n.t(translation)
        },
        getURLImage(image) {
            return css_image_url + image
        }
    },

    mounted() {
        this.typeOfBox = this.typeOfBox[this.gType] ? this.typeOfBox[this.gType] : this.typeOfBox.default
    }
}
</script>

<style lang="scss" scoped>
.main-container-box {
    margin: 2rem 1rem;
    padding: 1rem 1rem;
    border-radius: 3px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &.connection {
        background-color: $warning-t60;
    }
    &.default {
        background-color: $color-warning-100;
    }
    &.checklist {
        height: 50%;
        flex-direction: column;
        justify-content: initial;
        gap: 1rem;
        .content-box {
            gap: 0.5rem;
            .title {
                font-weight: bold;
                margin: 5px 0;
                &::after {
                    display: none;
                }
            }
            .subtitle {
                font-size: 16px;
                line-height: 14px;
                p {
                    text-align: center;
                }
            }
        }
    }

    .content-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .offline-image{
            width: 100px;
            height: 100px;
            @include background($image: img('andy-surprise.svg'), $position: center, $size: contain, $repeat: no-repeat);
        }

        .title {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -18%;
                @include background($image: img('no-connection.svg'), $position: center, $size: contain, $repeat: no-repeat);
                width: 20px;
                height: 20px;
            }
        }
    }

    button {
        padding: 0.5rem 1rem;
        border-radius: 3px;
        border: none;
        font-weight: bold;
        cursor: pointer;
        color: $color-white;
        font-size: 18px;
        &.connection,
        &.checklist {
            background-color: $main;
            padding-left: 2rem;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 5%;
                @include background($image: img('reload-retry.svg'), $position: center, $size: contain, $repeat: no-repeat);
                width: 16px;
                height: 16px;
            }
        }
    }

    &.portrait {
        flex-direction: column;
        margin: 1.5rem 0;

        .content-box {
            font-size: 16px;
            margin-bottom: 8px;
            .title {
                width: max-content;
            }
        }
    }
}
</style>
