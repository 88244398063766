<template>
    <div class="checklist-category-card" :class="[$mq]">
        <div class="category-card-container" @click="selectCategory()" :class="{ selected: category.id == selected }">
            <div class="graph-container">
                <div class="graph chart">
                    <div class="c100" :class="['p' + category.score, itemClasses]">
                        <span class="number">{{ category.score }}%</span>
                        <div class="slice">
                            <div class="bar"></div>
                            <div class="fill"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="name-category" v-html="category.name"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChecklistCategory',
    props: {
        category: {
            type: Object,
            default: false
        },
        itemClasses: {
            type: undefined,
            default: false
        },
        selected: {
            type: undefined,
            default: false
        }
    },
    computed: {},
    methods: {
        selectCategory() {
            this.$emit('selectCategory', this.category)
        }
    }
}
</script>

<style lang="scss">
.checklist-category-card {
    width: 100%;
    height: 100%;
    max-height: 100px;

    .category-card-container {
        @include display-flex();
        @include align-items(center);
        @include border-radius(5px);
        @include background($image: img('right_neutro.svg'), $position: right 12px center, $size: 9px);
        width: 100%;
        height: 100%;
        background-color: #fff;
        cursor: pointer;
        .graph-container {
            width: fit-content;
            height: auto;
            padding: 12px;

            .chart {
                padding: unset;
                width: auto;
                height: auto;

                > .c100 > .number {
                    line-height: 4.2rem;
                    @include font-size(sm);
                    background-color: transparent;
                }
                > .c100 {
                    margin: 0px;
                }
            }
        }

        .name-category {
            @include text-ellipsis();
            @include font-size(sm);
            width: 100%;
            font-family: $text-bold;
            padding-right: 35px;
        }
        &:hover:not(.selected) {
            @include bgHover(#fff);
        }

        &.selected {
            @include background($image: img('right_light.svg'), $position: right 12px center, $size: 14px);
            background-color: $main;
            border-radius: 4px 0px 0px 4px;
            position: relative;
            width: 98%;
            left: 2%;

            .name-category {
                @include font-size(m);
                color: #fff;
                font-family: $text-bold;
            }

            .c100 {
                .number {
                    @include display-flex();
                    @include align-items(center);
                    @include justify-content(center);
                    background-color: #fff !important;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    left: 7.5px;
                    top: 7.5px;
                }
            }
        }
    }
    &.landscape {
        .category-card-container {
            .graph-container {
                .c100 {
                    .number {
                        font-size: 14px;
                    }
                }
            }
            &.selected {
                .c100 {
                    .number {
                        @include display-flex();
                        @include align-items(center);
                        @include justify-content(center);
                        background-color: $main !important;
                        border-radius: 50%;
                        // width: 45px;
                        // height: 45px;
                        // left: 5.9px;
                        // top: 6.2px;
                        color: #fff;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>
