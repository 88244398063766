<template>
    <div v-if="check" ref="container" id="detail-content" class="content scrollbar right" :class="[{ 'hide-scrollbar': ['portrait'].includes($mq) }, $mq]">
        <div class="top-container" :class="{ infoOpened: infoIsOpen }">
            <AppccTopDetail :hasReopen="showButton()" :hasDelete="showButton()" :hasMoreOptions="canDelete()" @infoOpened="infoOpened" @deleteButton="removeRegister()" @reopenButton="reopenReport()" @settingsButton="settingsRegister" @backButton="backToDetail()" :userInfo="check.employee" :title="check.title" :createdAt="check.created_date" :completed="check.complete_date" :toolType="type"></AppccTopDetail>
        </div>
        <div class="template-sections" v-if="sections.length > 1">
            <Template-sections :sections="sections"></Template-sections>
        </div>
        <div class="detail-box" :class="[{ 'with-sections': sections.length > 1 }, { 'sidebar-collapsed': collapsedInfo }, { 'not-saved': !check.created_date }, { 'opened-info': infoIsOpen }]">
            <div class="exam performing" id="exam" :class="[{ 'hide-scrollbar': ['portrait'].includes($mq) }, { scrollbar: ['landscape', 'desktop'].includes($mq) }]">
                <div class="message" v-if="!check.complete_date">
                    <Message :message="$t('register.draft')" type="warning"></Message>
                </div>
                <div v-if="check.description" class="check-description" @click="view_more = !view_more">
                    <div class="message keep-format" v-html="descriptionParse(check.description)"></div>
                </div>

                <div v-if="check.recover && !check.complete_date" class="get-last-values">
                    <span class="message" @click="recoverValues()">{{ $t('tasks.get_last_values') }}</span>
                </div>

                <template v-if="check">
                    <answer v-for="answer in itemsSorted" :item="answer" :list="check" :cId="check.id" :aId="answer.item_id" :cType="type" :key="answer.item_id" :showErrors="showErrors"></answer>
                </template>
            </div>
        </div>
    </div>
    <div v-else class="content hide-scrollbar right empty">
        <!-- <div class="message empty">
            {{ getString('register.empty') }}
        </div> -->
        <EmptyPage :buttonText="$t('empty_pages.return_logs')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_record')"></EmptyPage>
    </div>
</template>

<script>
import EmptyPage from '@/components/layout/EmptyPage'
import answer from '@/components/domain/tasks/answer'
import UserTag from '@/components/layout/UserTag'
import AppccTopDetail from '@/components/layout/AppccTopDetail'
import TemplateSections from '@/components/layout/TemplateSections'
import Message from '@/components/domain/ui/Message'
export default {
    components: { answer, UserTag, EmptyPage, AppccTopDetail, Message, TemplateSections },
    props: {
        gId: { type: String },
        showErrors: {
            type: Boolean
        },
        permissions: {
            type: Boolean
        }
    },
    data() {
        return {
            collapsedInfo: false,
            type: 3,
            infoIsOpen: false,
            view_more: false,
            showNonApply: true,
            isRegister: true
        }
    },
    computed: {
        check() {
            var check = this.$store.getters['register/getById'](this.gId)
            if (check == undefined) {
                this.$bar.hide()
            }
            return check
        },
        sections() {
            var sections = []
            if (this.check && this.check.items && this.check.def) {
                for (var i in this.check.items) {
                    if (this.check.def.item.itemsTitle.indexOf(this.check.items[i].type) != -1) {
                        sections.push(this.check.items[i])
                    }
                }
            }
            return sections
        },
        hasConnection() {
            return fnCheckConnection()
        },
        itemsSorted() {
            if (this.check.items) {
                return Object.values(this.check.items).sort((a, b) => {
                    return a.order - b.order
                })
            }
        },
        removeRolesLevel() {
            return this.$store.getters['login/getRemoveRolesLevel']
        },
        roles() {
            return this.$store.getters['employee/getAllRoles']
        },
        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        userRole() {
            var user = this.$store.getters['employee/getEmployee'](this.emp_id)
            if (user && Object.keys(this.roles).length > 0) {
                return this.roles[user.rol]
            }
        },
        user() {
            return this.$store.getters['employee/getEmployee'](this.emp_id)
        }
    },
    methods: {
        rolesWithWritePermissions() {
            const permissions = this.$store.getters['register/getTemplateById'](this.$route.params.id)
            const roles = []
            if (permissions && permissions.config && permissions.config.nRoles && permissions.config.nRoles.length > 0) {
                const idOfRoles = permissions.config.nRoles.map((role) => {
                    if (role.permissions.includes('w')) {
                        return role.roleId
                    }
                })
                Object.values(this.roles).map((role) => {
                    if (idOfRoles.includes(role.id)) {
                        roles.push(role.name)
                    }
                })
            }

            return roles
                .join(', ')
                .toString()
                .replace(/,(?=[^,]*$)/, ' ' + this.$t('answer.or'))
        },
        rolesWithManagePermissions() {
            const permissions = this.$store.getters['register/getTemplateById'](this.$route.params.id)
            const roles = []
            if (permissions && permissions.config && permissions.config.nRoles && permissions.config.nRoles.length > 0) {
                const idOfRoles = permissions.config.nRoles.map((role) => {
                    if (role.permissions.includes('x')) {
                        return role.roleId
                    }
                })
                Object.values(this.roles).map((role) => {
                    if (idOfRoles.includes(role.id)) {
                        roles.push(role.name)
                    }
                })
            }

            return roles
                .join(', ')
                .toString()
                .replace(/,(?=[^,]*$)/, ' ' + this.$t('answer.or'))
        },
        reopenReport() {
            var self = this
            if (!this.permissions) {
                this.$popup.alert({
                    message: this.$t('answer.timing.not_has_role_permission', {
                        name: this.user.name,
                        roles: this.rolesWithManagePermissions()
                    })
                })
                return
            }
            if (!fnCheckConnection()) {
                this.$popup.alert({
                    message: this.$t('popup.register.offline_reopen')
                })
                return
            }

            this.$store.dispatch('register/update', { checklist: this.gId, status: 0 })
            this.$bar.reset()
            this.$bar.addAction('save', {
                callback: () => self.$emit('saveButton')
            })
        },
        settingsRegister() {
            const self = this
            this.$popup.itemSettings({
                itemSettings: {
                    noApply: {
                        label: self.$t('popup.item_settings.no_apply'),
                        value: self.showNonApply
                    }
                },
                callSave: (settings) => {
                    const itemSettings = settings.itemSettings
                    self.showNonApply = itemSettings.noApply.value
                    self.$popup.close()
                }
            })
        },
        removeRegister() {
            if (!this.permissions) {
                this.$popup.alert({
                    message: this.$t('answer.timing.not_has_role_permission', {
                        name: this.user.name,
                        roles: this.rolesWithWritePermissions()
                    })
                })
                return
            }

            if (!fnCheckConnection()) {
                this.$popup.alert({
                    message: this.$t('popup.register.offline_remove')
                })
                return
            }

            const employee_id = this.$store.getters['loginUser/getLocalEmployee']
            const template_id = this.$route.params.id
            var self = this
            this.$popup.delete({
                message: this.$t('register.delete_msg', { register: this.check.title }),
                textSave: this.$t('popup.delete.text_save'),
                textCancel: this.$t('popup.delete.text_cancel'),
                callSave: function () {
                    self.$overlay.loading()
                    self.$store
                        .dispatch('register/delete', { check: self.gId, emp: employee_id })
                        .then((response) => {
                            self.$overlay.hide()
                            self.$popup.close()
                            self.$router.push({ name: 'RegisterDetail' }, { id: template_id })
                        })
                        .catch((error) => {
                            logError(error)
                            self.$overlay.hide()
                            self.$popup.close()
                            self.$router.push({ name: 'RegisterDetail' }, { id: template_id })
                        })
                }
            })
        },

        recoverValues() {
            var self = this
            self.$overlay.loading()

            self.$store.dispatch('register/recoverValues', { check: self.gId }).then(function (response) {
                self.$overlay.hide()
                self.$snackbar.info({ message: self.$t('tooltip.done') })
            })
        },

        canDelete() {
            if(this.check.config && (!this.check.config.nRoles)){
                return true
            }

            // REGISTER IS ON DRAFT = TRUE
            if (this.check && this.check.status == 0) {
                return true
            }
            // IF HAS REMOVEROLESLEVEL CHECK IF USERROLE LEVEL IS
            else if (this.removeRolesLevel) {
                if (this.userRole && parseInt(this.userRole.level) >= parseInt(this.removeRolesLevel)) {
                    return true
                } else return false
            }
            // USER ROLE >= 2 = TRUE
            else if (this.userRole && parseInt(this.userRole.level) >= 2) {
                return true
            }
            // DEFAULT HIDE
            else return false
        },

        infoOpened() {
            this.infoIsOpen = !this.infoIsOpen
        },
        back() {
            this.$router.push({ name: 'Register' })
        },
        backToDetail() {
            var self = this
            // if (!this.hasConnection) {
            //     self.$popup.confirm({
            //         message: self.$t('register.cancel_uncomplete'),
            //         textSave: self.$t('register.cancel_close'),
            //         textCancel: self.$t('register.cancel_complete'),
            //         callCancel: function (popup, store, item) {
            //             if (item == 'button') {
            //                 self.$router.push({
            //                     name: 'RegisterDetail',
            //                     params: { id: self.$route.params.id }
            //                 })
            //             } else {
            //                 popup.callSave(popup, store)
            //             }
            //         }
            //     })
            // } else {
            self.$router.push({
                name: 'RegisterDetail',
                params: { id: self.$route.params.id, noRefresh: true }
            })
            // }
        },
        descriptionParse(msg) {
            if (msg.split(' ').length < 100) {
                return msgParse(msg)
            } else {
                if (this.view_more) {
                    return msgParse(msg) + ' <span class="more"> ' + this.$t('label.less') + '</span>'
                } else {
                    var maxLength = 150 // maximum number of characters to extract

                    // if portrait mode, set maxLength to 100
                    if (this.$mq == 'portrait') {
                        maxLength = 60
                    }

                    var trimmedMsg = msg.substr(0, maxLength)

                    trimmedMsg = trimmedMsg.substr(0, Math.min(trimmedMsg.length, trimmedMsg.lastIndexOf(' ')))
                    return msgParse(trimmedMsg) + '... <span class="more">' + this.$t('label.more') + '</span>'
                }
            }
        },
        getString(str) {
            return this.$t(str)
        },
        getStringVariables(str, params) {
            return this.$t(str, params)
        },
        getStringPlural(str, pos) {
            return this.$tc(str, pos)
        },
        getStringVariable(str, pos) {
            return this.$t(str, { s: pos })
        },
        getStringPluralVariable(str, pos, text) {
            return this.$tc(str, pos, { s: text })
        },
        redirectPath(firstResult) {
            log(this.$root.redirectPath(firstResult))
            return this.$root.redirectPath(firstResult)
        },
        showButton() {
            if(this.check.config && (!this.check.config.nRoles)){
                return true
            }
            if (this.check && this.check.config && Array.isArray(this.check.config.nRoles) && this.check.config.nRoles.length > 0) {
                let permissions = ''
                this.check.config.nRoles.forEach((employee) => {
                    if (employee.roleId === this.user.rol) {
                        permissions += employee.permissions
                    }
                })
                return permissions.includes('rwx') || permissions.includes('rx')
            }
            return false
        }
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    mounted() {
        if (this.gId == 'add') {
            this.$overlay.loading()
        }
    },
    created() {
        if (localStorage.getItem('showNonApply') != undefined) {
            this.showNonApply = localStorage.getItem('showNonApply') == 'true' ? true : false
        }
    },
    watch: {
        gId(newValue, oldValue) {
            this.$overlay.hide()
        },
        showNonApply(newValue, oldValue) {
            if (newValue !== oldValue) {
                localStorage.setItem('showNonApply', newValue)
                this.$store.commit('checklist/setShowNoApply', newValue)
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.register.detail {
    padding: 0px;
    $column-width: 0%;
    $column-max-width: rem(0px);
    $content-width: 100%;

    > .content {
        $top-info: rem(70px);
        $resume-width: rem(100px);
        overflow: hidden !important;
        padding: 8px !important;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;

        .detail-box {
            $sidebar-size: rem(40px);
            $collapse-button: rem(40px);
            $content-padding: rem(0px);
            $detail-bg: $default-bg-color;
            $detail-text-color: $main-dark;

            position: relative;
            padding: 0;
            padding-left: $content-padding;
            width: 100%;
            height: calc(100% - 185px);
            background-color: $detail-bg;
            overflow: hidden;

            &.with-sections {
                // padding-top: $top-info + $sidebar-size;
            }

            .message {
                padding-left: 10px;
            }

            .exam {
                @include border-radius($default-border-radius);
                height: 100%;
                overflow: auto;
                // min-height: 100%;

                .get-last-values {
                    text-align: center;

                    .message {
                        @include interaction();
                        @include border-radius($default-border-radius);
                        @include background($color: $main-t80, $image: img('download_primary.svg'), $position: right 12px center, $size: 22px);
                        @include font-size(m);
                        font-family: $text;
                        color: $main-dark;
                        padding: 12px 42px 12px 12px;
                        display: inline-block;

                        @include bgHover($main-t80);
                    }
                }
            }
        }

        .check-description {
            @include background($color: shade($default-bg-color, 4%), $image: img('file_attach_aaaaaa.svg'), $position: left 12px center, $size: 35px);
            @include display-flex();
            @include align-items(center);
            border-radius: 4px;
            padding: 16px 20px 16px 56px;
            height: auto;
            min-height: 40px;
            width: calc(100% - 18px);
            margin-left: 15px;

            .message {
                @include font-size(s);
                padding-left: 4px;
                font-family: $text;
                color: $neutro-s90;
                display: inline-block;
                overflow: visible;
                width: auto;
                min-height: 20px;
                height: auto;

                .more {
                    color: $main-t20;
                    cursor: pointer;
                }
            }
        }
        .top-container {
            background-color: $default-bg-color;
            width: 100%;
            height: auto;
        }
        .template-sections {
            width: 100%;
            height: 50px;
        }
        &.desktop {
            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding-bottom: 10px;
                max-height: 106px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 120px);
                &.with-sections {
                    height: calc(100% - 155px);

                    &.opened-info {
                        height: calc(100% - 185px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 135px);
                }
            }
            .exam.performing {
                > .message {
                    > .document.plugin.warning.desktop {
                        padding: 0px 4px 5px 0px !important;
                    }
                }

                .check-description {
                    .message {
                        @include font-size(s);
                    }
                }
            }
        }
        &.landscape {
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 115px);

                &.with-sections {
                    height: calc(100% - 160px);

                    &.opened-info {
                        height: calc(100% - 185px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 130px);
                }
            }
            .exam {
                .message {
                    .document.landscape {
                        width: 99% !important;
                    }
                }
            }
        }
    }

    > .column.left {
        $content-space: 6px;
        width: $column-width !important;
        max-width: $column-max-width !important;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.portrait {
    .content.portrait {
        margin-top: 0 !important;
        .detail-box {
            background: transparent;
            height: calc(100% - 63px);
            padding-bottom: 0px;

            .message {
                padding-left: 0px;
            }

            .exam {
                height: calc(100% - 63px);
                overflow: auto;

                .justify-description,
                .check-available {
                    padding: 8px 20px 8px 50px;
                    background-size: 25px;
                    background-position: left 10px center;

                    .message {
                        @include font-size(xs);
                        line-height: 1rem;
                        width: calc(100% - 100px);
                    }
                    .action {
                        width: 100px;
                        @include font-size(xs);
                        line-height: 0.75rem;
                    }
                }
                .check-available {
                    .message {
                        width: 100%;
                        max-width: 100%;
                    }
                }
                .check-description {
                    padding: 8px 20px 8px 34px;
                    width: 100%;
                    margin-left: 0px;
                    background-position: left 4px center;
                    background-size: 25px;
                    .message {
                        @include font-size(xs);
                    }
                }

                .get-last-values {
                    .message {
                        @include font-size(xs);
                        line-height: normal;
                    }
                }
            }
            &.not-saved {
                height: calc(100% - 115px);
                padding-bottom: 0px;
            }
            &.opened-info {
                height: calc(100% - 115px);
                padding-bottom: 0px;
            }
            &.with-sections {
                height: calc(100% - 112px);
                padding-bottom: 0px;

                &.opened-info {
                    height: calc(100% - 162px);
                    padding-bottom: 0px;
                }
            }
        }
        .top-container {
            height: auto;
            max-height: 115px;
        }
    }
}
</style>
